import ReactGA from "react-ga4";
import {
  useLogout,
  useCurrentUser,
  useFetchPracticeUsers,
  useFetchPractices,
} from "utils/hooks";
import { toast } from "react-toastify";
import { User } from "types";

interface removeUserFromPracticeProps {
  practiceId: string;
  member: User;
}

const useRemoveUserFromPractice = () => {
  const { logOut } = useLogout();
  const { fetchPracticeUsers } = useFetchPracticeUsers();
  const { fetchPractices } = useFetchPractices();
  const { user } = useCurrentUser();

  const removeUserFromPractice = async ({
    practiceId,
    member,
  }: removeUserFromPracticeProps) => {
    const { email, uuid } = member;

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              removeTherapistFromPractice(
                practiceId: "${practiceId}",
                therapistId: "${uuid}",
                email: "${email}",
              )
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "remove_practice_members",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        ReactGA.event("user_removed_from_practice", {
          user_id: user?.id,
          removed_user_id: member.uuid,
          practice_id: practiceId,
          connection_was: member.status,
        });
        toast(`Therapist has been removed from practice`);
        fetchPractices();
        fetchPracticeUsers(practiceId);
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    removeUserFromPractice,
  };
};

export default useRemoveUserFromPractice;
