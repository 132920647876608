import { useEffect } from "react";
import { useSelector } from "react-redux";
import useFetchLibrary from "./useFetchLibrary";

const useGetPublicLibrary = () => {
  const { fetchPublicLibrary } = useFetchLibrary();

  const resources = useSelector((state) => state.resources && state.resources);

  useEffect(() => {
    if (resources?.resources?.length === 0) {
      fetchPublicLibrary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    publicResources: resources.resources?.sort(
      (a, b) => b.createdAt - a.createdAt
    ),
  };
};

export default useGetPublicLibrary;
