import { PURGE } from "redux-persist";
import { Assignment } from "types";

type AssignmentsProps = {
  type: string;
  payload: any;
};

const initialState = {
  assignments: null as Array<Assignment> | null,
  groupAssignments: null as Array<Assignment> | null,
  unsavedReminderSettings: [],
  unsavedConfigureSettings: false,
};

export default (state = initialState, { type, payload }: AssignmentsProps) => {
  switch (type) {
    case "FETCH_PATIENT_ASSIGNMENTS":
      return {
        ...state,
        assignments: [...payload],
      };
    case "FETCH_GROUP_ASSIGNMENTS":
      return {
        ...state,
        groupAssignments: [...payload],
      };
    case "ADD_PATIENT_ASSIGNMENT":
      if (!state.assignments) return state;
      return {
        ...state,
        assignments: [...state.assignments, payload],
      };
    case "ADD_GROUP_ASSIGNMENT":
      if (!state.groupAssignments) return state;
      return {
        ...state,
        groupAssignments: [...state.groupAssignments, payload],
      };
    case "DELETE_PATIENT_ASSIGNMENT":
      if (!state.assignments) return state;
      return {
        ...state,
        assignments: state.assignments.filter(
          (assignment) => assignment.uuid !== payload.uuid
        ),
      };
    case "DELETE_GROUP_ASSIGNMENT":
      if (!state.groupAssignments) return state;
      return {
        ...state,
        groupAssignments: state.groupAssignments.filter(
          (assignment) => assignment.uuid !== payload.uuid
        ),
      };
    case "UPDATE_NOTIFICATION":
      if (!state.assignments) return state;
      return {
        ...state,
        assignments: state.assignments.map((assignment) =>
          assignment.uuid === payload.entityId
            ? {
                ...assignment,
                notification: {
                  ...payload,
                },
              }
            : assignment
        ),
      };
    case "UPDATE_GROUP_NOTIFICATION":
      if (!state.groupAssignments) return state;
      return {
        ...state,
        groupAssignments: state.groupAssignments.map((assignment) =>
          assignment.uuid === payload.entityId
            ? {
                ...assignment,
                notification: {
                  ...payload,
                },
              }
            : assignment
        ),
      };
    case "UPDATE_PATIENT_ASSIGNMENT":
      if (!state.assignments) return state;
      const newAssignments = state.assignments.filter(
        (item) => item.uuid !== payload.uuid
      );
      return {
        ...state,
        assignments: [...newAssignments, payload],
      };
    case "UPDATE_GROUP_ASSIGNMENT":
      if (!state.groupAssignments) return state;
      const newGroupAssignments = state.groupAssignments.filter(
        (item) => item.uuid !== payload.uuid
      );
      return {
        ...state,
        groupAssignments: [...newGroupAssignments, payload],
      };
    case "UPDATE_UNSAVED_REMINDER_SETTINGS":
      return {
        ...state,
        unsavedReminderSettings: [...payload],
      };
    case "UPDATE_UNSAVED_CONFIGURE_SETTINGS":
      return {
        ...state,
        unsavedConfigureSettings: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
