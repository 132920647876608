import React from "react";
import ClampLines from "react-clamp-lines";
import { useTranslation } from "react-i18next";
import "./CollectionCard.styles.scss";
import { Icon } from "components";
import { LibraryResource, Collection } from "types";

interface CollectionCardProps {
  collection: Collection;
  title: string;
  items: number;
  imageSrc?: string;
  description?: string;
  collectionId: string;
  selected?: Collection | undefined;
  showEdit?: boolean;
  showDelete?: boolean;
  onEditCollection?: (collection: any) => void;
  onOpenDeleteModal?: ({
    link,
    collection,
  }: {
    link?: LibraryResource;
    collection?: Collection;
  }) => void;
}

const CollectionCard = ({
  collection,
  title,
  imageSrc,
  items,
  description,
  collectionId,
  selected,
  showEdit,
  showDelete,
  onEditCollection,
  onOpenDeleteModal,
}: CollectionCardProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="collection_wrapper bg-white">
      <div className="collection_header flex">
        <div
          className={`collection_header_icon ${
            selected && "collection_header_icon-checked"
          } flex`}
        >
          <Icon
            src={selected ? "CheckBold" : "CollectionIcon"}
            alt="folder with discs"
          />
        </div>
        <ClampLines
          text={title}
          id={collectionId}
          className="collection-card-clamped-title"
          lines={2}
          buttons={false}
        />
      </div>

      <div className="collection_image-container">
        <div className="collection_image_wrapper">
          {imageSrc && (
            <img
              className="collection_image"
              src={imageSrc}
              alt="collection's first resource preview"
            />
          )}
        </div>
        <div className="collection_sticker">
          <span>{items + " " + t("resources.collection_card_links")}</span>
        </div>
      </div>

      {description && (
        <ClampLines
          text={description}
          id="collection-card-clamped-description"
          className="collection-card-clamped-description"
          lines={4}
          ellipsis="..."
          innerElement="p"
          buttons={false}
        />
      )}

      <div className="collection_actions">
        {showDelete && (
          <span
            className="collection_actions_action"
            onClick={() =>
              onOpenDeleteModal && onOpenDeleteModal({ collection })
            }
            onKeyDown={() =>
              onOpenDeleteModal && onOpenDeleteModal({ collection })
            }
            role="button"
            tabIndex={0}
          >
            <Icon src={"Trash"} alt="delete collection" />
          </span>
        )}
        {showEdit && (
          <span
            className="collection_actions_action"
            onClick={() => onEditCollection && onEditCollection(collection)}
            onKeyDown={() => onEditCollection && onEditCollection(collection)}
            role="button"
            tabIndex={0}
          >
            <Icon src={"EditIcon"} alt="edit collection" />
          </span>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
