import { useMemo, useState, useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Collection } from "types";
import { useCurrentUser } from "utils/hooks";

type Props = {
  patientId?: string;
  groupId?: string;
};

const useGetCollections = ({ patientId, groupId }: Props) => {
  const { user } = useCurrentUser();
  const templateMasterId = process.env.REACT_APP_TEMPLATE_MASTER_ID;

  const [patientCollections, setPatientCollections] =
    useState<Array<Collection> | null>(null);
  const [patientPublicCollections, setPatientPublicCollections] =
    useState<Array<Collection> | null>(null);
  const [groupCollections, setGroupCollections] =
    useState<Array<Collection> | null>(null);
  const [groupPublicCollections, setGroupPublicCollections] =
    useState<Array<Collection> | null>(null);

  const collectionsState = useSelector(
    (state: RootStateOrAny) => state.collections && state.collections
  );

  const allCollections = collectionsState.collections;

  const userCollections = useMemo(
    () => allCollections?.filter((c: Collection) => c.userId === user?.id),
    [allCollections, user]
  );

  const publicCollections = useMemo(
    () =>
      allCollections?.filter((c: Collection) => c.userId === templateMasterId),
    [allCollections, templateMasterId]
  );

  useEffect(() => {
    if (!patientId || !user || !allCollections) return;
    const collectionSharedToPatient = allCollections.filter(
      (collection: Collection) => collection.sharedWith.includes(patientId)
    );
    const collectionsByTherapist = collectionSharedToPatient.filter(
      (c: Collection) => c.userId === user?.id
    );
    const collectionsByTMaster = collectionSharedToPatient.filter(
      (c: Collection) => c.userId === templateMasterId
    );
    setPatientCollections(collectionsByTherapist);
    setPatientPublicCollections(collectionsByTMaster);
  }, [patientId, user, templateMasterId, allCollections]);

  useEffect(() => {
    if (!groupId || !allCollections) return;
    const collectionsSharedToGroup = allCollections.filter(
      (collection: Collection) => collection.sharedWith.includes(groupId)
    );

    const collectionsCreatedByTherapist = collectionsSharedToGroup.filter(
      (collection: Collection) => collection.userId === user?.id
    );

    const collectionsCreatedByTempMaster = collectionsSharedToGroup.filter(
      (collection: Collection) =>
        collection.userId === process.env.REACT_APP_TEMPLATE_MASTER_ID
    );
    setGroupCollections(collectionsCreatedByTherapist);
    setGroupPublicCollections(collectionsCreatedByTempMaster);
  }, [groupId, allCollections, user]);

  return {
    collections: allCollections,
    userCollections,
    publicCollections,
    patientCollections,
    patientPublicCollections,
    groupCollections,
    groupPublicCollections,
  };
};

export default useGetCollections;
