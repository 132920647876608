import { useState } from "react";
import ReactGA from "react-ga4";
import {
  updateLibraryResources,
  addPublicPatientLibraryResourcesAction,
  addGroupLibraryResources,
  updatePublicLibraryResourcesAction,
} from "store/actions/library";
import { updateAssignedCollectionsAction } from "store/actions/collections";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";
import { Collection, LibraryResource } from "types";

type Props = {
  resourceIds: Array<string> | [];
  collectionIds: Array<string> | [];
  patientId?: string;
  groupId?: string;
};

const useAssignLibraryResources = ({}) => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [assigned, setAssigned] = useState(false);

  const formatIds = (ids: Array<string> | []) => ids.map((id) => `"${id}"`);

  const assignLibraryResources = ({
    resourceIds,
    collectionIds,
    patientId,
    groupId,
  }: Props) => {
    setAssigned(false);
    setLoading(true);

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                assignMultipleLibraryResourcesToPatientOrGroup(
                  resourceIds: [${formatIds(resourceIds ? resourceIds : [])}], 
                  collectionIds: [${formatIds(
                    collectionIds ? collectionIds : []
                  )}],
                  patientId: ${patientId ? `"${patientId}"` : `""`},
                  groupId: ${groupId ? `"${groupId}"` : `""`}
                ) {
                  libraryResources {
                    resourceUrl
                    title
                    description
                    imageUrl
                    uuid
                    addedBy
                    updatedAt
                    sharedWith {
                      label
                      value
                    }
                  }
                  collections {
                    uuid
                    userId
                    type
                    title
                    description
                    defaultCollection
                    collectionResources {
                      uuid
                      resourceUrl
                      title
                      description
                      imageUrl
                      addedBy
                      sharedWith {
                        value
                        label
                      }
                    }
                    sharedWith 
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "assign_library_resources",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              setLoading(false);
              return true;
            } else {
              toast("Resources added!");
              resolve();
              setLoading(false);
              ReactGA.event("assign_library_resources_success", {
                user_id: user?.id,
                resources:
                  resData.data.assignMultipleLibraryResourcesToPatientOrGroup,
              });
              const resourcesFromCollections =
                resData.data.assignMultipleLibraryResourcesToPatientOrGroup.collections
                  .map((c: Collection) => c.collectionResources)
                  .flat();
              const publicResources =
                resData.data.assignMultipleLibraryResourcesToPatientOrGroup.libraryResources.map(
                  (r: LibraryResource) =>
                    r.uuid === process.env.REACT_APP_TEMPLATE_MASTER_ID
                );
              const publicResourcesFromCollections =
                resourcesFromCollections.filter(
                  (r: LibraryResource) =>
                    r.addedBy === process.env.REACT_APP_TEMPLATE_MASTER_ID
                );
              dispatch(
                updateLibraryResources([
                  ...resData.data.assignMultipleLibraryResourcesToPatientOrGroup
                    .libraryResources,
                  ...resourcesFromCollections,
                ])
              );
              dispatch(
                updateAssignedCollectionsAction(
                  resData.data.assignMultipleLibraryResourcesToPatientOrGroup
                    .collections
                )
              );
              if (patientId) {
                dispatch(
                  addPublicPatientLibraryResourcesAction([
                    ...publicResources,
                    ...publicResourcesFromCollections,
                  ])
                );
              }
              if (groupId) {
                dispatch(
                  addGroupLibraryResources([
                    ...resData.data
                      .assignMultipleLibraryResourcesToPatientOrGroup
                      .libraryResources,
                    ...resourcesFromCollections,
                  ])
                );
                dispatch(
                  updatePublicLibraryResourcesAction([
                    ...publicResources,
                    ...publicResourcesFromCollections,
                  ])
                );
              }
              setAssigned(true);
              return true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    assignLibraryResources,
    loading,
    assigned,
  };
};

export default useAssignLibraryResources;
