import React, { useState, useEffect } from "react";
import "./CheckboxGrid.styles.scss";
import Checkbox from "../Checkbox/Checkbox";
import {
  Field,
  CheckboxOption,
  CheckboxRow,
  SelectedCheckboxOption,
} from "types";

interface CheckboxGridProps extends Field {
  label: string;
  description?: string;
  checkboxes?: CheckboxOption[];
  checkboxRows?: CheckboxRow[];
  onCheckboxRowsChange?: (rowsWithOptions: string) => void;
  allowMulti?: boolean;
  verticalStack?: boolean;
  showCheckboxScoring?: boolean;
}

const CheckboxGrid: React.FC<CheckboxGridProps> = ({
  label,
  description,
  checkboxes = [],
  checkboxRows = [],
  onCheckboxRowsChange,
  allowMulti = false,
  verticalStack = true,
}) => {
  const [localCheckboxRows, setLocalCheckboxRows] = useState<
    CheckboxRow[] | undefined
  >(checkboxRows);

  useEffect(() => {
    setLocalCheckboxRows(checkboxRows);
  }, [checkboxRows]);

  const handleCheckboxChange = (
    rowId: string,
    checkboxId: string | undefined,
    checkboxScore?: number
  ) => {
    if (!checkboxId) return;

    const updatedRows = localCheckboxRows?.map((row) => {
      if (row.id === rowId) {
        const isSelected = row.selectedCheckboxOptions?.some(
          (option) => option.checkedId === checkboxId
        );

        const updatedselectedCheckboxOptions: SelectedCheckboxOption[] =
          allowMulti
            ? isSelected
              ? (row.selectedCheckboxOptions ?? []).filter(
                  (option) => option.checkedId !== checkboxId
                )
              : [
                  ...(row.selectedCheckboxOptions ?? []), // Ensure this is always an array
                  { checkedId: checkboxId, score: checkboxScore },
                ]
            : isSelected
            ? []
            : [{ checkedId: checkboxId, score: checkboxScore }];

        return {
          ...row,
          selectedCheckboxOptions: updatedselectedCheckboxOptions,
        };
      }
      return row;
    });

    setLocalCheckboxRows(updatedRows);

    if (onCheckboxRowsChange) {
      const serializedData = JSON.stringify({
        rows: updatedRows || [],
        options: checkboxes,
      });
      onCheckboxRowsChange(serializedData);
    }
  };

  return (
    <div className="checkbox-grid">
      <div className="checkbox-grid__label">{label}</div>
      <div className="checkbox-grid__description">{description}</div>
      {localCheckboxRows?.map((row) => (
        <div key={row.id} className="checkbox-grid__row">
          <div className="checkbox-grid__row-label">{row.label}</div>
          {row.description && (
            <div className="checkbox-grid__row-description">
              {row.description}
            </div>
          )}
          <div
            className={`checkbox-grid__container ${
              verticalStack ? "vertical" : "horizontal"
            }`}
          >
            {checkboxes.map((checkbox) => {
              if (!checkbox.id) return null;

              const isChecked = row.selectedCheckboxOptions?.some(
                (option) => option.checkedId === checkbox.id
              );

              return (
                <div
                  className={`checkbox-grid__item ${
                    verticalStack ? "vertical" : ""
                  }`}
                  key={checkbox.id}
                >
                  <Checkbox
                    round={!allowMulti}
                    large
                    id={`${row.id}-${checkbox.id}`}
                    label={checkbox.label}
                    checked={isChecked}
                    onChange={() =>
                      handleCheckboxChange(row.id, checkbox.id, checkbox.score)
                    }
                    stackLabelSide={verticalStack}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGrid;
