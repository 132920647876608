import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toAmericanDateWithTimeFromUnix, toAmericanDate } from "utils/helpers";
import { IconButton } from "components";
import { Note } from "types";

interface Props {
  handleOpenDeleteModal: (note: Note) => void;
}
const useNotesDataColumns = ({ handleOpenDeleteModal }: Props) => {
  const { t } = useTranslation(["common"]);

  const notesDataColumns = useMemo(
    () => [
      {
        header: "Date",
        cell: (props: any) => {
          return (
            <>
              <b>{toAmericanDate(props.row.original.date)}</b>
            </>
          );
        },
      },
      {
        header: "",
        accessorKey: "noteText",
        enableResizing: false,
        cell: (props: any) => {
          return <div className="note-snippet">{props.row.original.body}</div>;
        },
      },
      {
        header: "Created",
        accessorKey: "createdAt",
        cell: (props: any) => {
          return (
            <>
              <span className="light-text">
                {toAmericanDateWithTimeFromUnix(props.row.original.createdAt)}
              </span>
            </>
          );
        },
      },
      {
        header: "Updated",
        accessorKey: "updatedAt",
        cell: (props: any) => {
          return (
            <>
              <span className="light-text">
                {toAmericanDateWithTimeFromUnix(props.row.original.updatedAt)}
              </span>
            </>
          );
        },
      },
      {
        header: "",
        id: "delete-note-buttons",
        cell: (props: any) => {
          return (
            <IconButton
              value={t("common_labels.remove")}
              icon="Remove"
              name="note_remove_btn"
              extraClass="note_delete_btn"
              onClick={() => handleOpenDeleteModal(props.row.original)}
            />
          );
        },
      },
    ],
    [t, handleOpenDeleteModal]
  );
  return notesDataColumns;
};

export default useNotesDataColumns;
