import { useEffect, useState, useRef } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useGetPatients from "../patients/useGetPatients";
import useFetchLibrary from "./useFetchLibrary";
import useFetchFiles from "../files/useFetchFiles";
import { FileResource, LibraryResource } from "types";

interface UseGetTherapistLibraryProps {
  patientSlug?: string;
  withFiles?: boolean;
}

const useGetTherapistLibrary = ({
  patientSlug,
  withFiles = false,
}: UseGetTherapistLibraryProps) => {
  const dataRef = useRef(false);
  const { fetchTherapistLibrary } = useFetchLibrary();
  const { fetchTherapistFiles } = useFetchFiles();
  const { patient } = useGetPatients({ slug: patientSlug });
  const [patientLibrary, setPatientLibrary] = useState<Array<LibraryResource>>(
    []
  );
  const [patientFiles, setPatientFiles] = useState<Array<FileResource>>([]);

  const resources = useSelector(
    (state: RootStateOrAny) => state.resources && state.resources
  );
  const files = useSelector(
    (state: RootStateOrAny) => state.files && state.files
  );

  useEffect(() => {
    if (dataRef.current) return;
    fetchTherapistLibrary();
    if (withFiles) {
      fetchTherapistFiles();
    }
    dataRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterLibraryByPatientId = (
    array: LibraryResource[] | [],
    string: any
  ) => {
    const filteredArray = array.filter((element) => {
      const hasPatient = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasPatient) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  const filterFilesByPatientId = (array: FileResource[] | [], string: any) => {
    const filteredArray = array.filter((element) => {
      const hasPatient = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasPatient) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  useEffect(() => {
    if (patient && patient.uuid && resources?.therapistResources?.length) {
      setPatientLibrary(
        filterLibraryByPatientId(resources.therapistResources, patient.uuid)
      );
    }
  }, [resources, patient]);

  useEffect(() => {
    if (patient && patient.uuid && files?.therapistFiles?.length) {
      setPatientFiles(
        filterFilesByPatientId(files.therapistFiles, patient.uuid)
      );
    }
  }, [files, patient]);

  return {
    therapistResources: resources?.therapistResources,
    therapistFiles: files?.therapistFiles || [],
    patientLibrary: patientLibrary.filter((item) => item),
    patientFiles: patientFiles.filter((file: any) => file),
  };
};

export default useGetTherapistLibrary;
