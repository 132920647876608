import "./RangeField.styles.scss";
import useRangeField from "./RangeField.hooks";
import React, { useRef, InputHTMLAttributes } from "react";
import { ScoreMappings } from "types";

interface RangeFieldProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "defaultValue" | "onChange"
  > {
  name: string;
  label: string;
  defaultValue?:
    | { value: number; score: number; textValue: string }
    | number
    | string;
  hint?: string;
  min?: number;
  max?: number;
  error?: string;
  minLabel?: string;
  maxLabel?: string;
  scoreMappings?: ScoreMappings[];
  onChange?: (value: string) => void;
}

const RangeField = ({
  value,
  label,
  hint,
  min = 0,
  max = 100,
  name,
  error,
  defaultValue = JSON.stringify({ value: 5, score: 0, textValue: "5" }),
  onChange,
  minLabel,
  maxLabel,
  scoreMappings = [],
  ...props
}: RangeFieldProps) => {
  const bubbleRef = useRef<HTMLDivElement>(null);
  const step = 1;

  const parsedValue = (() => {
    if (typeof value === "string") {
      try {
        return JSON.parse(value);
      } catch {
        return {
          value: Number(value),
          score: Number(value),
          textValue: value.toString(),
        };
      }
    }
    if (typeof value === "number") {
      return { value, score: value, textValue: value.toString() };
    }
    return value;
  })();

  const parsedDefaultValue = (() => {
    if (typeof defaultValue === "string") {
      try {
        return JSON.parse(defaultValue);
      } catch {
        return {
          value: Number(defaultValue),
          score: Number(defaultValue),
          textValue: defaultValue.toString(),
        };
      }
    }
    if (typeof defaultValue === "number") {
      return {
        value: defaultValue,
        score: defaultValue,
        textValue: defaultValue.toString(),
      };
    }
    return defaultValue;
  })();

  const { currentValue, textValue, handleValueChange, getFinalValueObject } =
    useRangeField({
      value: parsedValue,
      id: name,
      min,
      max,
      step,
      defaultValue: parsedDefaultValue,
      bubbleRef,
      scoreMappings,
    });

  const handleChange = (newVal: number) => {
    const newValueObject = getFinalValueObject(newVal);
    onChange && onChange(JSON.stringify(newValueObject));
    handleValueChange(newVal);
  };

  return (
    <div className="range-field">
      <label className="range-field__label">{label}</label>
      <div className="range-field__input-wrapper">
        <div ref={bubbleRef} className="range-field__bubble">
          {textValue}
        </div>
        <input
          aria-label={label}
          className={`range-field__field ${error ? "error" : ""}`}
          min={min}
          max={max}
          step={step}
          value={currentValue}
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(Number(e.target.value))
          }
          type="range"
        />
      </div>
      {(minLabel || maxLabel) && (
        <div className="range-field__labels">
          <span className="range-field__label--min">{minLabel}</span>
          <span className="range-field__label--max">{maxLabel}</span>
        </div>
      )}
      {error && (
        <p className="range-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="range-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default RangeField;
