export const fetchPublicLibraryAction = (resources) => {
  return {
    type: "FETCH_PUBLIC_LIBRARY",
    payload: resources,
  };
};

export const fetchTherapistLibraryAction = (resources) => {
  return {
    type: "FETCH_THERAPIST_LIBRARY",
    payload: resources,
  };
};

export const fetchGroupLibraryAction = (resources) => {
  return {
    type: "FETCH_GROUP_LIBRARY",
    payload: resources,
  };
};

export const fetchPatientLibraryAction = (resources) => {
  return {
    type: "FETCH_PATIENT_LIBRARY",
    payload: resources,
  };
};

export const addLibraryResource = (resource) => {
  return {
    type: "ADD_THERAPIST_LIBRARY_RESOURCE",
    payload: resource,
  };
};

export const addGroupLibraryResources = (resources) => {
  return {
    type: "ADD_GROUP_LIBRARY_RESOURCES",
    payload: resources,
  };
};

export const updateLibraryResources = (resources) => {
  return {
    type: "UPDATE_THERAPIST_LIBRARY_RESOURCES",
    payload: resources,
  };
};

export const updateGroupLibraryResource = (resources) => {
  return {
    type: "UPDATE_GROUP_LIBRARY_RESOURCE",
    payload: resources,
  };
};

export const deleteLibraryResourceAction = (resourceId) => {
  return {
    type: "DELETE_THERAPIST_LIBRARY_RESOURCE",
    payload: resourceId,
  };
};

export const deleteGroupLibraryResourcesAction = (resourceIds) => {
  return {
    type: "DELETE_GROUP_LIBRARY_RESOURCES",
    payload: resourceIds,
  };
};

export const fetchPublicPatientLibraryAction = (resources) => {
  return {
    type: "FETCH_PUBLIC_PATIENT_LIBRARY",
    payload: resources,
  };
};

export const updatePublicLibraryResourcesAction = (resources) => {
  return {
    type: "UPDATE_PUBLIC_LIBRARY_RESOURCES",
    payload: resources,
  };
};

export const deletePublicPatientLibraryResourceAction = (resource) => {
  return {
    type: "DELETE_PUBLIC_PATIENT_LIBRARY_RESOURCE",
    payload: resource,
  };
};

export const updatePublicPatientLibraryAction = (resources) => {
  return {
    type: "UPDATE_PUBLIC_PATIENT_LIBRARY",
    payload: resources,
  };
};

export const deletePublicLibraryResourceAction = (resourceId) => {
  return {
    type: "DELETE_PUBLIC_LIBRARY_RESOURCE",
    payload: resourceId,
  };
};

export const addPublicPatientLibraryResourcesAction = (resources) => {
  return {
    type: "ADD_PUBLIC_PATIENT_LIBRARY",
    payload: resources,
  };
};
