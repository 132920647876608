export const credentialOptions = [
  { label: "LSW", value: "LSW" },
  { label: "LCSW", value: "LCSW" },
  { label: "LMFT", value: "LMFT" },
  { label: "LPC", value: "LPC" },
  { label: "PhD", value: "PhD" },
  { label: "PsyD", value: "PsyD" },
];

export const clientFocusAreaOptions = [
  { label: "Addiction/Substance Use", value: "Addiction/Substance Use" },
  { label: "Alcohol Use", value: "Alcohol Use" },
  { label: "Anger Management", value: "Anger Management" },
  { label: "Anxiety", value: "Anxiety" },
  { label: "Autism Spectrum Disorder", value: "Autism Spectrum Disorder" },
  { label: "Bipolar Disorder", value: "Bipolar Disorder" },
  { label: "Depression", value: "Depression" },
  { label: "Grief/ Loss", value: "Grief/ Loss" },
  { label: "Marital", value: "Marital" },
  { label: "Obsessive-Compulsive", value: "Obsessive-Compulsive" },
  { label: "Trauma & PTSD", value: "Trauma & PTSD" },
];

export const treatmentPreferencesOptions = [
  {
    label: "ACT (Acceptance Commitment Therapy)",
    value: "ACT (Acceptance Commitment Therapy)",
  },
  {
    label: "CBT (Cognitive Behavioral Therapy)",
    value: "CBT (Cognitive Behavioral Therapy)",
  },
  {
    label: "DBT (Dialectical Behavioral Therapy)",
    value: "DBT (Dialectical Behavioral Therapy)",
  },
  {
    label: "IFS (Internal Family Systems)",
    value: "IFS (Internal Family Systems)",
  },
  {
    label: "EFT (Emotionally Focused Therapy)",
    value: "EFT (Emotionally Focused Therapy)",
  },
  { label: "Psychoanalytic", value: "Psychoanalytic" },
  { label: "Psychodynamic", value: "Psychodynamic" },
  { label: "Solution Focused", value: "Solution Focused" },
  { label: "Family Systems", value: "Family Systems" },
  { label: "Trauma Focused", value: "Trauma Focused" },
];

export const clientFocusOptions = [
  { label: "Elders (65+)", value: "Elders (65+)" },
  { label: "Adults", value: "Adults" },
  { label: "Teens", value: "Teens" },
  { label: "Preteen", value: "Preteen" },
  { label: "Children (6-10)", value: "Children (6-10)" },
  { label: "Toddler", value: "Toddler" },
];

export const organizationNameOptions = [
  {
    label: "Individual Private Practice",
    value: "Individual Private Practice",
  },
];

export const practiceSizeOptions = [
  {
    label: "1 (just me)",
    value: "1",
  },
  {
    label: "2 - 5",
    value: "2 - 5",
  },
  {
    label: "6 - 10",
    value: "6 - 10",
  },
  {
    label: "11 - 20",
    value: "11 - 20",
  },
  {
    label: "20+",
    value: "20+",
  },
];

export const stateOptions = [
  { label: "Non-US", value: "Non-US" },
  { label: "Alaska", value: "Alaska" },
  { label: "Alabama", value: "Alabama" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "Arizona", value: "Arizona" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "District of Columbia", value: "District of Columbia" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Iowa", value: "Iowa" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Maryland", value: "Maryland" },
  { label: "Maine", value: "Maine" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Missouri", value: "Missouri" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Montana", value: "Montana" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "Nevada", value: "Nevada" },
  { label: "New York", value: "New York" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Virginia", value: "Virginia" },
  { label: "Vermont", value: "Vermont" },
  { label: "Washington", value: "Washington" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wyoming", value: "Wyoming" },
];

export const countryOptions = [
  { label: "USA", value: "USA" },
  { label: "Israel", value: "Israel" },
];
