import React from "react";
import { useSpring, animated } from "react-spring";

interface GaugeChartProps {
  score?: number;
  maxScore?: number;
  label?: string;
  color?: string;
}

const GaugeChart: React.FC<GaugeChartProps> = ({
  score = 0,
  maxScore = 21,
  label = "Severe",
  color,
}) => {
  const percentage = Math.min(1, Math.max(0, score / maxScore));
  const angle = percentage * 180; // Convert percentage to angle (half-circle)

  const animatedProps = useSpring({
    to: { angle },
    from: { angle: 0 },
    config: { tension: 120, friction: 14 },
  });

  const radius = 60;
  const strokeWidth = 8;
  const centerX = 75;
  const centerY = 75;

  const getArcPath = (angle: number) => {
    const startX = centerX - radius;
    const startY = centerY;
    const endX = centerX + radius * Math.cos((angle - 180) * (Math.PI / 180));
    const endY = centerY + radius * Math.sin((angle - 180) * (Math.PI / 180));

    return `M ${startX},${startY} A ${radius},${radius} 0 ${
      angle > 180 ? 1 : 0
    },1 ${endX},${endY}`;
  };

  return (
    <div className="flex flex-col items-center">
      <svg width="150" height="90" viewBox="0 0 150 90">
        {/* Background Arc */}
        <path
          d="M 15,75 A 60,60 0 0,1 135,75"
          fill="none"
          stroke="#e0e0e0"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        {/* Animated Foreground Arc */}
        <animated.path
          d={animatedProps.angle.to(getArcPath)}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
      </svg>
      <div className="text-center mt-2">
        <p className="text-sm font-semibold">Score</p>
        <p className="text-2xl font-bold">{score}</p>
        <p className="text-md">{label}</p>
      </div>
    </div>
  );
};

export default GaugeChart;
