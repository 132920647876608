import React, { useEffect, useState } from "react";

interface ButtonLoadingProps {
  entity?: string;
}

const ButtonLoading = ({ entity = "account" }: ButtonLoadingProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const loadingStates = [
    `Creating ${entity}...`,
    "Securing credentials...",
    "Verifying encryption...",
    "Almost done...",
  ];

  useEffect(() => {
    if (currentIndex === loadingStates.length - 1) {
      return;
    }
    const interval = setInterval(() => {
      const updatedData = currentIndex + 1;
      setCurrentIndex(updatedData);
    }, 2500);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return <span>{loadingStates[currentIndex]}</span>;
};

export default ButtonLoading;
