import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { setAuthenticatedUser } from "store/actions/auth";
import { toast } from "react-toastify";
import { useLogin } from "utils/hooks";

export interface SignUpArgs {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  isTherapist: boolean | string;
}

interface UseSignUpProps {
  fromInvite?: string | null;
  inviteTherapistToken?: string | null;
  qrTokenParam?: string | null;
  fbclid?: string | null;
}

const useSignUp = ({
  fromInvite,
  inviteTherapistToken,
  qrTokenParam,
  fbclid,
}: UseSignUpProps) => {
  const dispatch = useDispatch();
  const { submitLogin } = useLogin();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submitSignup = (args: SignUpArgs | null): Promise<boolean | void> => {
    if (!args) {
      return Promise.resolve();
    }

    const userType =
      args.isTherapist === "true" ||
      args.isTherapist === true ||
      inviteTherapistToken
        ? "therapist"
        : "patient";
    const isFromInvite = fromInvite || inviteTherapistToken;

    setIsSubmitting(true);

    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          body: JSON.stringify({
            query: `
              mutation {
                createUser(user: {
                  firstName: "${args.first_name}",
                  lastName: "${args.last_name}",
                  email: "${args.email}",
                  password: "${args.password}",
                  type: "${userType}",
                  inviteToken: "${fromInvite}",
                  inviteTherapistToken: "${inviteTherapistToken}",
                  fbclid: "${fbclid}",
                }) {
                  uuid
                  email
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: "NA",
            permissionKey: "sign_up",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              toast.error("Hmm, something went wrong.");
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              if (resData.data.createUser.uuid) {
                ReactGA.event("signup_success", {
                  user_id: resData.data.createUser.uuid,
                  user_type: userType,
                  from_invite_link: isFromInvite,
                });
              }
              dispatch(setAuthenticatedUser(resData.data.createUser));
            }
          })
          .then(() => {
            return submitLogin({
              email: args.email,
              password: args.password,
              qrTokenParam,
              fromInvite,
              inviteTherapistToken,
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }, 100);
    });
  };

  return {
    submitSignup,
    isSubmitting,
  };
};

export default useSignUp;
