import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { updatePatientAction } from "store/actions/patients";
import { toast } from "react-toastify";
import { useCurrentUser, useFetchPatients, useFetchGroups } from "utils/hooks";

export interface EditPatientProfileArgs {
  first_name: string;
  last_name: string;
  dob: string;
  gender?: { value: string };
  newGroups: GroupOption[];
  removedGroups: GroupOption[];
}
interface GroupOption {
  value: string;
  label: string;
}
interface UseEditPatientProfileProps {
  patientId?: string;
}

const useEditPatientProfile = ({ patientId }: UseEditPatientProfileProps) => {
  const dispatch = useDispatch();
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [isProfileSubmitting, setIsProfileSubmitting] =
    useState<boolean>(false);
  const { fetchAllPatients } = useFetchPatients();
  const { user } = useCurrentUser();
  const { fetchGroups } = useFetchGroups();

  const formatGroups = (groups: GroupOption[] | undefined) => {
    return groups ? groups.map((group) => `"${group.value}"`) : [];
  };

  const updatePatientProfile = async (
    args: EditPatientProfileArgs
  ): Promise<void> => {
    if (!args || !user) {
      return;
    }

    setIsProfileSubmitting(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              updatePatientProfile(patient: {
                patientId: "${patientId}",
                firstName: "${args.first_name}",
                lastName: "${args.last_name}",
                dob: "${args.dob}",
                gender: "${args.gender?.value}",
                newGroupIds: [${formatGroups(args?.newGroups)}],
                removedGroupIds: [${formatGroups(args?.removedGroups)}],
              }) {
                firstName
                lastName
                profile {
                  dob
                  gender
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user.activeOrganizationId || "NA",
          permissionKey: "update_patient",
        },
      });

      if (res.status !== 200 && res.status !== 201) {
        setIsProfileSubmitting(false);
        toast.error("Hmm, something went wrong.");
        throw new Error("Failed to update patient profile");
      }

      const resData = await res.json();

      if (resData.errors) {
        setIsProfileSubmitting(false);
        toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
        return;
      }

      if (resData.data.updatePatientProfile) {
        setIsProfileSubmitting(false);
        fetchAllPatients();
        fetchGroups();
        toast("Patient profile has been updated!");
        setIsUpdateSuccessful(true);
        ReactGA.event("patient_profile_updated", {
          user_id: user.id,
          patient_id: patientId,
        });
        dispatch(updatePatientAction(resData.data.updatePatientProfile));
      }
    } catch (err) {
      setIsProfileSubmitting(false);
      console.error("Error updating patient profile:", err);
    }
  };

  return {
    updatePatientProfile,
    isProfileSubmitting,
    isUpdateSuccessful,
  };
};

export default useEditPatientProfile;
