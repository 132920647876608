import React from "react";
import { useCurrentUser } from "utils/hooks";

interface RangeSliderResponseProps {
  value: string | number | null | undefined;
  condensed?: boolean;
  showScore?: boolean;
}

const RangeSliderResponse: React.FC<RangeSliderResponseProps> = ({
  value,
  condensed,
  showScore,
}) => {
  const { isTherapist } = useCurrentUser();
  if (typeof value === "string" && value.trim() !== "") {
    try {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === "object" && parsedValue.textValue) {
        return (
          <div className={condensed ? "" : "mb-3"}>
            <div className="flex align-center">
              <small style={{ display: "inline-block", width: "75px" }}>
                <i>Response:</i>
              </small>
              {parsedValue.textValue}
            </div>
            {isTherapist && showScore && (
              <div className="flex align-center">
                <small style={{ display: "inline-block", width: "75px" }}>
                  <i>Score:</i>
                </small>
                {parsedValue.score}
              </div>
            )}
          </div>
        );
      } else {
        console.warn(
          "SCALE value is not in the expected object format, falling back to value:",
          value
        );
        return (
          <div className="flex align-center">
            <small style={{ display: "inline-block", width: "75px" }}>
              <i>Response:</i>
            </small>
            {parsedValue.value || value}
          </div>
        );
      }
    } catch (error) {
      console.error("Error parsing SCALE value:", error);
      return <div className="flex align-center">{value}</div>;
    }
  } else if (typeof value === "number") {
    return <div className="flex align-center">{value}</div>;
  } else {
    console.warn("Unexpected SCALE value format:", value);
    return <div className="flex align-center">{value || "N/A"}</div>;
  }
};

export default RangeSliderResponse;
