import "./LibraryPage.styles.scss";
import React, { useEffect, useState, useRef } from "react";
import {
  PageTitle,
  PageActionBar,
  SidePanel,
  Modal,
  TopNav,
  PageLoading,
} from "components";
import {
  useCurrentUser,
  useViewport,
  useGetTherapistLibrary,
  useFetchLibrary,
  useGetPatientLibrary,
} from "utils/hooks";
import Resource from "./components/Resource";
import FileCard from "pages/AppPages/TherapistPages/Resources/components/Files/components/File";
import AddLibraryResourceButton from "./components/AddLibraryResourceButton";
import AddLibraryResourceForm from "./components/AddLibraryResourceForm";
import DeleteResourceConfirmationModal from "./components/DeleteResourceConfirmationModal";
import LibraryEmptyState from "./components/LibraryEmptyState";
import { useTranslation } from "react-i18next";
import { LibraryResource, FileResource } from "types";
import { addType, filterAndSortArray } from "./utils";

const LibraryPage = () => {
  const dataRef = useRef(false);
  const { t } = useTranslation(["common"]);
  const [filteredResources, setFilteredResources] = useState<Array<any> | null>(
    null
  );
  const [showResourceForm, setShowResourceForm] = useState(false);
  const [currentResource, setCurrentResource] = useState<
    LibraryResource | FileResource | null
  >(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { therapistResources } = useGetTherapistLibrary({});
  const { patientResources, patientFiles } = useGetPatientLibrary();
  const { fetchPublicLibrary } = useFetchLibrary();
  const { isDesktop } = useViewport();
  const { isTherapist, user } = useCurrentUser();
  const title = isTherapist
    ? t("patient_nav_items.resources")
    : t("patient_library_page.your_library_label");

  useEffect(() => {
    let resources = null;
    if (therapistResources) {
      resources = [...therapistResources];
    }
    if (patientResources) {
      const updatedPatientResources = addType(patientResources, "resource");
      resources = resources
        ? [...resources, ...updatedPatientResources]
        : [...updatedPatientResources];
    }
    if (patientFiles) {
      const updatedPatientFiles = addType(patientFiles, "file");
      resources = resources
        ? [...resources, ...updatedPatientFiles]
        : [...updatedPatientFiles];
    }
    setFilteredResources(resources ? filterAndSortArray(resources) : resources);
  }, [therapistResources, patientResources, patientFiles]);

  const handleEditResource = (resource: LibraryResource | FileResource) => {
    setCurrentResource(resource);
    setShowResourceForm(!showResourceForm);
  };

  const handleOpenDeleteModal = (resource: LibraryResource | FileResource) => {
    setCurrentResource(resource);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setCurrentResource(null);
    setIsDeleteModalOpen(false);
  };

  const handleCloseSidePanel = () => {
    setShowResourceForm(false);
    setCurrentResource(null);
  };

  useEffect(() => {
    if (dataRef.current) return;
    fetchPublicLibrary();
    dataRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopNav topL={<PageTitle text={title} />} />
      <div className="library-page">
        {isTherapist && isDesktop && (
          <PageActionBar>
            <div className="library-page-header">
              {isTherapist && (
                <AddLibraryResourceButton
                  onClick={() => {
                    setShowResourceForm(!showResourceForm);
                  }}
                />
              )}
            </div>
          </PageActionBar>
        )}

        <div className="library-page-body">
          <div className="library-page-section">
            {filteredResources &&
              filteredResources?.length > 0 &&
              filteredResources?.map((item) => {
                if (item.type === "resource") {
                  return (
                    <Resource
                      resource={item}
                      key={item.uuid}
                      isOwner={item.addedBy === user?.id}
                      onEditResource={handleEditResource}
                      onOpenDeleteModal={handleOpenDeleteModal}
                    />
                  );
                }
                if (item.type === "file") {
                  return (
                    <FileCard
                      file={item}
                      key={item.uuid}
                      isOwner={item.addedBy === user?.id}
                      handleFileEditClick={() => handleEditResource(item)}
                      handleFileDeleteClick={() => handleOpenDeleteModal(item)}
                    />
                  );
                }
                return null;
              })}
            {filteredResources?.length === 0 && <LibraryEmptyState />}
            {!filteredResources && <PageLoading />}
          </div>
        </div>
      </div>
      {isTherapist && (
        <SidePanel
          title={currentResource ? "Edit Resource" : "Add Resource"}
          isVisible={showResourceForm}
          onClose={handleCloseSidePanel}
        >
          <AddLibraryResourceForm
            onSuccess={handleCloseSidePanel}
            resource={currentResource}
          />
        </SidePanel>
      )}
      {isTherapist && (
        <Modal isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
          <DeleteResourceConfirmationModal
            resource={currentResource}
            onCancel={handleCloseDeleteModal}
          />
        </Modal>
      )}
    </>
  );
};

export default LibraryPage;
