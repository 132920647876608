import React from "react";
import { DataTable } from "components";
import { useViewport } from "utils/hooks";
import NoteDataCards from "../NoteDataCards";
import { Note } from "types";

interface NotesTableProps {
  data: any;
  notesDataColumns: any;
  onRowClick: any;
  onRowHover: any;
  handleOpenDeleteModal: (note: Note) => void;
}

const NotesTable = ({
  data,
  notesDataColumns,
  onRowClick,
  onRowHover,
  handleOpenDeleteModal,
}: NotesTableProps) => {
  const { isDesktop } = useViewport();

  const rowProps = (row: any, rowRef: any) => {
    if (rowRef) {
      rowRef.onmouseover = () => {
        onRowHover(row.original);
      };
      rowRef.onclick = (e: Event) => {
        if ((e.target as HTMLElement).closest(".note_delete_btn")) return;
        onRowClick(row.original);
      };
    }
  };

  return (
    <>
      {isDesktop ? (
        <DataTable
          data={data}
          columns={notesDataColumns}
          dataType="note"
          id="notes-table"
          rowProps={rowProps}
        />
      ) : (
        <NoteDataCards
          notes={data}
          onClick={(note: Note) => onRowClick(note)}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      )}
    </>
  );
};

export default NotesTable;
