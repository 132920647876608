import "./SubmissionsDataCards.styles.scss";
import React from "react";
import { SubmissionListItem } from "components";

const SubmissionDataCards = ({
  submissions,
  onShowSubmission,
  onSetSubmission,
}) => {
  const handleSubmissionListItemClick = (submission) => {
    onShowSubmission(true);
    onSetSubmission(submission);
  };

  return (
    <>
      {submissions?.length > 0 && (
        <>
          {submissions.map((submission, i) => {
            return (
              <SubmissionListItem
                submission={submission}
                key={i}
                onClickHandler={(sub) => handleSubmissionListItemClick(sub)}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default SubmissionDataCards;
