import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchPracticesAction } from "store/actions/practices";
import { useCurrentUser, useLogout } from "utils/hooks";

const useFetchPractices = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchPractices = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getPractices {
                uuid
                createdAt
                updatedAt
                practiceName
                treatmentType
                practiceSize
                address1
                address2
                state
                zipcode
                phone
                slug
                organizationId
                status
                numberOfUsers
                practiceOwnerId
                logoId
                logoUrl
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "read_practice_information",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        dispatch(fetchPracticesAction(resData.data.getPractices));
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    fetchPractices,
  };
};

export default useFetchPractices;
