import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import useFetchTemplates from "./useFetchTemplates";
import { updateTemplateAction } from "store/actions/templates";

type Props = {
  templateId?: string;
  defaultTemplate: boolean;
};

const useUpdateTemplateSubscription = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchPrivateTemplates, fetchPublicTemplates } = useFetchTemplates();
  const { user } = useCurrentUser();

  const updateTemplateSubscription = ({
    templateId,
    defaultTemplate,
  }: Props) => {
    if (!templateId) {
      return null;
    }

    setLoading(true);
    setIsUpdateSuccessful(false);

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateTemplateSubscription(
                    templateId: "${templateId}",
                    defaultTemplate: ${defaultTemplate}
                ) {
                  title
                  description
                  instructions
                  authorId
                  slug
                  order
                  content
                  icon
                  color
                  uuid
                  status
                  category
                  type
                  assignedCount
                  default
                  tags {
                    uuid
                    title
                    color
                    type
                    authorId
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "update_template_subscription",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setLoading(false);
              resolve();
              return true;
            } else {
              resolve();
              setLoading(false);
              setIsUpdateSuccessful(true);
              dispatch(
                updateTemplateAction(resData.data.updateTemplateSubscription)
              );
              fetchPrivateTemplates();
              fetchPublicTemplates();
              ReactGA.event("update_template_subscription_success", {
                user_id: user?.id,
                template: resData.data.updateTemplateSubscription,
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    updateTemplateSubscription,
    loading,
    isUpdateSuccessful,
  };
};

export default useUpdateTemplateSubscription;
