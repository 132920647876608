import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useFetchFiles from "./useFetchFiles";

const useGetPatientFiles = () => {
  const dataRef = useRef(false);
  const { fetchPatientFiles } = useFetchFiles();

  const files = useSelector((state) => state.files && state.files);

  useEffect(() => {
    if (dataRef.current) return;
    fetchPatientFiles();
    dataRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    patientFiles: files?.patientFiles,
    patientPublicFiles: files?.patientPublicFiles || [],
  };
};

export default useGetPatientFiles;
