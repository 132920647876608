import { Checkbox } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

interface PatientSubmissionGroupPrivacySettingProps {
  onGroupPrivacySettingChange: (isGroupPrivate: boolean) => void;
  isGroupPrivate?: boolean | null;
}

const PatientSubmissionGroupPrivacySetting: React.FC<
  PatientSubmissionGroupPrivacySettingProps
> = ({ onGroupPrivacySettingChange, isGroupPrivate }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Checkbox
      name="submissionGroupPrivacy"
      label={t("patient_assignments.share_with_group_members_label")}
      ariaLabel={t("patient_assignments.share_with_group_members_label")}
      checked={!isGroupPrivate}
      onChange={() => onGroupPrivacySettingChange(!isGroupPrivate)}
      variant="no-margin"
    />
  );
};

export default PatientSubmissionGroupPrivacySetting;
