import { PURGE } from "redux-persist";

import { toUnixTimestamp } from "utils/helpers";

const initialState = {
  submissionDrafts: [],
  submissions: null,
  groupSubmissions: null,
};

const formatPayloadWithDateField = (payload) => {
  return payload.map((submission) => {
    return {
      ...submission,
      dateField:
        toUnixTimestamp(
          submission.responseFields.find((field) => field.fieldType === "DATE")
            ?.value
        ) || submission.createdAt,
    };
  });
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_SUBMISSION_DRAFT":
      // First, see if we already have a draft in state
      const currentSubmissionDraftState = state.submissionDrafts;
      const shouldUpdateExistingDraft =
        currentSubmissionDraftState &&
        currentSubmissionDraftState.some(
          (draft) =>
            draft.submission.assignmentId === payload.submission.assignmentId
        );
      // If we do, we'll only update the relevant fields
      const stateWithUpdate = () => {
        return state.submissionDrafts.map((submissionDraft) => {
          if (
            submissionDraft.submission.assignmentId !==
            payload.submission.assignmentId
          ) {
            return submissionDraft;
          }
          const responseSet = {};
          // Collect field by uuid into set
          (submissionDraft.responseFields || []).forEach(
            (field) => (responseSet[field.fieldId] = field)
          );
          // Overwrite field values with the same uuid
          (payload.responseFields || []).forEach(
            (field) => (responseSet[field.fieldId] = field)
          );

          return {
            submission: submissionDraft.submission,
            // Take response fields from set as array
            responseFields: Object.values(responseSet),
          };
        });
      };
      return {
        ...state,
        submissionDrafts: shouldUpdateExistingDraft
          ? stateWithUpdate()
          : [...state.submissionDrafts, payload],
      };
    case "CLEAR_SUBMISSION_DRAFT":
      return {
        ...state,
        submissionDrafts: state.submissionDrafts.filter(
          (draft) => draft.submission.assignmentId !== payload
        ),
      };
    case "CREATE_SUBMISSION":
      return {
        ...state,
        submissions: [...state.submissions, payload],
      };
    case "EDIT_SUBMISSION":
      return {
        ...state,
        submissions: state.submissions.map((submission) => {
          if (submission.uuid === payload.submissionId) {
            return {
              ...submission,
              updatedAt: payload.updatedAt,
              privacy: payload.privacy,
              groupPrivacy: payload.groupPrivacy,
            };
          }
          return submission;
        }),
      };
    case "FETCH_SUBMISSIONS":
      return {
        ...state,
        submissions: formatPayloadWithDateField(payload),
      };
    case "FETCH_GROUP_SUBMISSIONS":
      return {
        ...state,
        groupSubmissions: formatPayloadWithDateField(payload),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
