import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useCurrentUser, useLogout } from "utils/hooks";
import { fetchPracticeUsersAction } from "store/actions/practices";

const useFetchPracticeUsers = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchPracticeUsers = async (practiceId: string | null) => {
    if (!practiceId) {
      return;
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              query {
                getPracticeUsers(practiceId: "${practiceId}") {
                  uuid
                  firstName
                  lastName
                  email
                  slug
                  status
                  role
                  invitingTherapist
                  icon
                  color
                }
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "read_practice_members",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        dispatch(fetchPracticeUsersAction(resData.data.getPracticeUsers));
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    fetchPracticeUsers,
  };
};

export default useFetchPracticeUsers;
