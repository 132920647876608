import { RootStateOrAny, useSelector } from "react-redux";
import useCurrentUser from "../auth/useCurrentUser";

interface UseGetTemplatesProps {
  templateId?: string;
}

const useGetTemplates = ({ templateId }: UseGetTemplatesProps) => {
  const { user } = useCurrentUser();

  const publicTemplates = useSelector(
    (state: RootStateOrAny) => state.templates.publicTemplates
  );

  const privateTemplates = useSelector(
    (state: RootStateOrAny) => state.templates.privateTemplates
  );

  const publicTemplateTags =
    useSelector(
      (state: RootStateOrAny) => state.templates.publicTemplateTags
    ) || [];

  const privateTemplateTags =
    useSelector(
      (state: RootStateOrAny) => state.templates.privateTemplateTags
    ) || [];

  const builderDrafts = privateTemplates?.filter(
    (t: any) => t.status === "DRAFT"
  );

  const filteredPrivateTemplates = privateTemplates?.filter(
    (t: { status: string }) => t.status === "PUBLISHED"
  );

  const filteredPublicTemplates = publicTemplates?.filter(
    (t: { type: string }) => t.type !== "SAFETY_PLAN"
  );

  const allTemplates =
    privateTemplates && filteredPublicTemplates
      ? [...privateTemplates, ...filteredPublicTemplates]
      : [];

  const allOwnedTemplates =
    allTemplates?.filter(
      (t: { authorId: string }) => t.authorId === user?.id
    ) || [];

  const specificTemplate =
    allTemplates?.filter((t: { uuid: string }) => t.uuid === templateId)[0] ||
    null;

  const allPublishedAssignmentTemplates =
    filteredPrivateTemplates && filteredPublicTemplates
      ? [...filteredPrivateTemplates, ...filteredPublicTemplates].sort(
          (a, b) => {
            const fa = a.title.toLowerCase(),
              fb = b.title.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        )
      : null;

  return {
    publicTemplates: filteredPublicTemplates,
    privateTemplates: filteredPrivateTemplates,
    builderDrafts,
    allPublishedAssignmentTemplates,
    publicTemplateTags: publicTemplateTags,
    privateTemplateTags: privateTemplateTags,
    allOwnedTemplates,
    specificTemplate,
    draftAndPublishedPrivTemplates: privateTemplates,
  };
};

export default useGetTemplates;
