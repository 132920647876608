import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useSubmissionUtils from "pages/AppPages/Shared/Submissions/utils";
import SubmissionsTable from "pages/AppPages/Shared/Submissions/components/SubmissionsTable";
import SubmissionsTablePatientEmptyState from "pages/AppPages/Shared/Submissions/components/SubmissionsTablePatientEmptyState";
import usePatientSubmissionsDataColumns from "../../utils/usePatientSubmissionsDataColumns";
import { useFetchGroupUsers, useCurrentUser } from "utils/hooks";
import { PageLoading } from "components";

interface PatientsSubmissionsTableProps {
  groupId?: string;
  showGroupColumns?: boolean;
}

const PatientsSubmissionsTable = ({
  groupId,
  showGroupColumns = false,
}: PatientsSubmissionsTableProps) => {
  const submissionDataColumns =
    usePatientSubmissionsDataColumns(showGroupColumns);
  const { slug } = useParams();
  const { currentPatient, sortedSubmissions, showTableElements } =
    useSubmissionUtils(groupId);
  const { fetchGroupUsers } = useFetchGroupUsers();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (groupId) {
      fetchGroupUsers(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const filterPatientGroupSubmissions = (submissions: any[]) =>
    submissions.filter((submission: any) => submission.userId !== user?.id);

  return (
    <>
      {showTableElements && (
        <SubmissionsTable
          data={
            groupId
              ? filterPatientGroupSubmissions(sortedSubmissions)
              : sortedSubmissions
          }
          submissionDataColumns={submissionDataColumns}
          slug={slug}
          currentPatient={currentPatient?.value}
          groupId={groupId}
        />
      )}
      {sortedSubmissions?.length === 0 && (
        <SubmissionsTablePatientEmptyState groupId={groupId} />
      )}
      {!sortedSubmissions && <PageLoading />}
    </>
  );
};

export default PatientsSubmissionsTable;
