import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useGetPatients from "../patients/useGetPatients";
import { useFetchSubmissions } from "utils/hooks";

type Props = {
  patientSlug?: string;
  groupId?: string;
  slug?: string;
  id?: string;
  email?: string;
  currentPatient?: Array<any>;
};

const useGetSubmissions = ({
  patientSlug,
  groupId,
  slug,
  id,
  email,
  currentPatient,
}: Props) => {
  const { fetchGroupSubmissions } = useFetchSubmissions();
  const submissions = useSelector(
    (state: RootStateOrAny) => state.submissions && state.submissions
  );

  useEffect(() => {
    fetchGroupSubmissions(groupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const { patient } = useGetPatients({ email: email, slug: patientSlug });

  if (!submissions) {
    return {
      submissions: null,
      submission: null,
      patientSubmissions: null,
      groupSubmissions: null,
    };
  }

  const formatSubmission = (submission: any) => {
    if (!submission) {
      return null;
    }

    const formattedSections = submission.responseFields.reduce(
      (acc: any, cur: any) => {
        if (!acc[cur.sectionId])
          acc[cur.sectionId] = {
            sectionLabel: cur.sectionLabel,
            sectionId: cur.sectionId,
            sectionOrder: cur.sectionOrder,
            responseFields: [],
          };
        acc[cur.sectionId].responseFields.push(cur);
        return acc;
      },
      {}
    );

    return {
      ...submission,
      sections: Object.values(formattedSections),
      isFlagged: submission.isNegative,
    };
  };

  const submission = submissions.submissions?.find(
    (submission: any) =>
      (submission?.slug === slug && slug) || (submission?.uuid === id && id)
  );

  const groupSubmission = submissions.groupSubmissions?.find(
    (submission: any) =>
      (submission?.slug === slug && slug) || (submission?.uuid === id && id)
  );

  const filterByPatient = currentPatient || patient;

  const patientSubmissions = submissions.submissions?.filter(
    (submission: any) =>
      submission.userId === filterByPatient?.uuid &&
      submission.assignmentLabel !== "Safety Plan"
  );

  return {
    submissions: submissions.submissions,
    submission: formatSubmission(submission),
    patientSubmissions: patientSubmissions,
    groupSubmissions: submissions.groupSubmissions,
    groupSubmission: formatSubmission(groupSubmission),
  };
};

export default useGetSubmissions;
