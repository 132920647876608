import { SubmissionScore } from "types";
import RichTextDisplayField from "../RichTextDisplayField";
import "./ScoreCard.styles.scss";
import React from "react";

interface ScoreCardProps {
  score?: SubmissionScore | null;
}

const ScoreCard = ({ score }: ScoreCardProps) => {
  if (!score) {
    return <></>;
  }
  return (
    <div className="score-card">
      <div className="score-card__score">
        <span className="score-card__scoreValue" style={{ color: score.color }}>
          {score.totalScore}
        </span>
        <span className="score-card__scoreLabel" style={{ color: score.color }}>
          SCORE
        </span>
      </div>
      <div className="score-card__instructions">
        <span className="score-card__instructionsLabel">Instructions</span>
        <div className="score-card__instructionContent">
          <RichTextDisplayField value={score?.scoringInstructions} />
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
