export const noteCategories = [
  {
    label: "Uncategorized",
    value: "uncategorized",
  },
  {
    label: "Flag",
    value: "flag",
  },
];
