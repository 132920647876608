/* eslint-disable jsx-a11y/no-onchange */
import React, { useRef, useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import TableWrapper from "./components/TableWrapper";
import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import TableFooter from "./components/TableFooter";
import EmptyTableBody from "./components/EmptyTableBody";
import TablePagination from "./components/TablePagination";

type Props = {
  data: Array<any>;
  columns: ColumnDef<any, any>[];
  dataType: string;
  rowProps?: (row: any, rowRef: any) => void | undefined;
  cellProps?: ((cell: any, cellRef: any) => void | undefined) | undefined;
  id: string;
  emptyStateComponent?: any;
  isCellReduced?: boolean;
};

const DataTable = ({
  data,
  columns,
  dataType,
  rowProps,
  cellProps,
  id,
  emptyStateComponent,
  isCellReduced,
}: Props) => {
  const tableInstance = useReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageIndex: 0,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    getPageCount,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
    getFooterGroups,
  } = tableInstance;

  // Refs and state for handling scroll indicators
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const leftIndicatorRef = useRef<HTMLDivElement>(null);
  const rightIndicatorRef = useRef<HTMLDivElement>(null);

  const [showLeftIndicator, setShowLeftIndicator] = useState(false);
  const [showRightIndicator, setShowRightIndicator] = useState(false);

  // Function to update the visibility of scroll indicators
  const updateScrollIndicators = () => {
    if (tableWrapperRef.current) {
      const { scrollLeft, scrollWidth, clientWidth, offsetLeft } =
        tableWrapperRef.current;

      setShowLeftIndicator(scrollLeft > 0);
      setShowRightIndicator(scrollWidth > clientWidth + scrollLeft);

      if (leftIndicatorRef.current) {
        leftIndicatorRef.current.style.left = `${offsetLeft}px`;
      }

      if (rightIndicatorRef.current) {
        rightIndicatorRef.current.style.right = `${
          window.innerWidth - (offsetLeft + clientWidth)
        }px`;
      }
    }
  };

  // Attach event listeners on component mount
  useEffect(() => {
    const handleResize = () => updateScrollIndicators();

    const tableWrapper = tableWrapperRef.current;
    if (tableWrapper) {
      tableWrapper.addEventListener("scroll", updateScrollIndicators);
    }

    window.addEventListener("resize", handleResize);

    // Initial check
    updateScrollIndicators();

    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener("scroll", updateScrollIndicators);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="table-wrapper" ref={tableWrapperRef}>
      {showLeftIndicator && (
        <div
          className="table-scroll-indicator left"
          ref={leftIndicatorRef}
        ></div>
      )}
      {showRightIndicator && (
        <div
          className="table-scroll-indicator right"
          ref={rightIndicatorRef}
        ></div>
      )}
      <TableWrapper {...{ role: "table" }} id={id}>
        <TableHead headerGroups={getHeaderGroups()} />
        {/* Apply the table body props */}
        {data?.length ? (
          <TableBody
            page={getRowModel().rows}
            rowProps={rowProps}
            cellProps={cellProps}
            isCellReduced={isCellReduced}
          />
        ) : (
          <>
            {emptyStateComponent ? (
              emptyStateComponent
            ) : (
              <EmptyTableBody dataType={dataType} />
            )}
          </>
        )}
        <TableFooter footerGroups={getFooterGroups()} />
      </TableWrapper>
      {data?.length > 10 ? (
        <TablePagination
          goToPage={setPageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={getCanPreviousPage()}
          canNextPage={getCanNextPage()}
          pageCount={getPageCount}
          pageOptions={getPageOptions()}
          pageIndex={getState().pagination.pageIndex}
          pageSize={getState().pagination.pageSize.toString()}
          setPageSize={setPageSize}
        />
      ) : null}
    </div>
  );
};

export default DataTable;
