import { useState } from "react";
import ReactGA from "react-ga4";
import {
  updateLibraryResources,
  deleteGroupLibraryResourcesAction,
} from "store/actions/library";
import { updateCollectionAction } from "store/actions/collections";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";
import { LibraryResource } from "types";

type Props = {
  collectionId: string;
  patientId?: string;
  groupId?: string;
};

const useUnassignCollection = ({ collectionId, patientId, groupId }: Props) => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const unassignCollection = () => {
    setSuccess(false);
    setLoading(true);

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                unassignCollectionFromPatientOrGroup(
                  collectionId: ${collectionId ? `"${collectionId}"` : `""`}, 
                  patientId: ${patientId ? `"${patientId}"` : `""`},
                  groupId: ${groupId ? `"${groupId}"` : `""`}
                ) {
                uuid
                userId
                type
                title
                description
                defaultCollection
                collectionResources {
                  uuid
                  resourceUrl
                  title
                  description
                  imageUrl
                  sharedWith {
                    value
                    label
                  }
                }
                sharedWith
                  }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "unassign_collection",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              setLoading(false);
              return true;
            } else {
              toast("Collection removed");
              resolve();
              setLoading(false);
              ReactGA.event("unassign_collection_success", {
                user_id: user?.id,
                resource: resData.data.unassignCollectionFromPatientOrGroup,
              });
              const collectionResources =
                resData.data.unassignCollectionFromPatientOrGroup.collectionResources.map(
                  (r: LibraryResource) => r.uuid
                );
              dispatch(
                updateLibraryResources(
                  resData.data.unassignCollectionFromPatientOrGroup
                    .collectionResources
                )
              );
              dispatch(
                updateCollectionAction(
                  resData.data.unassignCollectionFromPatientOrGroup
                )
              );
              dispatch(deleteGroupLibraryResourcesAction(collectionResources));
              return setSuccess(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    unassignCollection,
    loading,
    success,
  };
};

export default useUnassignCollection;
