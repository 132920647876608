import { RootStateOrAny, useSelector } from "react-redux";
import { Group } from "types";

interface UseGetGroupsProps {
  groupId?: string | null;
  slug?: string;
}

const useGetGroups = ({ groupId, slug }: UseGetGroupsProps) => {
  const groups = useSelector(
    (state: RootStateOrAny) => state.groups.groups as Group[]
  );

  const specificGroup =
    groups?.filter((t: { uuid: string }) => t.uuid === groupId)[0] ||
    groups?.filter((t: { slug: string }) => t.slug === slug)[0] ||
    null;

  return {
    groups: groups,
    specificGroup,
  };
};

export default useGetGroups;
