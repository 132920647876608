import React from "react";
import { Button, UpgradeTooltip } from "components";
import { useCurrentUser, useGetTherapistFiles } from "utils/hooks";
import { useTranslation } from "react-i18next";

interface AddFileButtonProps {
  onClick: () => void;
  title?: string;
}

const AddFileButton = ({ onClick, title }: AddFileButtonProps) => {
  const { user } = useCurrentUser();
  const { therapistFiles } = useGetTherapistFiles({});

  const fileLimit = user?.plan?.fileLimit || 0;
  // if the user doesn't have a plan they are likley a patient who cannot currently add files anyway
  const limitReached = therapistFiles?.length >= fileLimit;

  const { t } = useTranslation(["common"]);
  return (
    <div data-tip data-for="file-upgrade-tip">
      <Button
        name="add_file_button"
        value={title || t("resources.add_file_button")}
        pill
        icon={"FileBold"}
        size="small"
        onClick={onClick}
        disabled={limitReached}
      />
      {limitReached && (
        <UpgradeTooltip
          tooltipId="file-upgrade-tip"
          limitedEntity="files"
          offset={{ left: 0 }}
        />
      )}
    </div>
  );
};

export default AddFileButton;
