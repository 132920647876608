import { PURGE } from "redux-persist";
import { FileResource } from "types";

type FileReducer = {
  type: string;
  payload: any;
};

const initialState = {
  files: null as Array<FileResource> | null,
  therapistFiles: null as Array<FileResource> | null,
  patientFiles: null,
  attachedFiles: [],
  patientPublicFiles: [],
  groupFiles: [] as Array<FileResource> | [],
};

export default (state = initialState, { type, payload }: FileReducer) => {
  switch (type) {
    case "FETCH_PUBLIC_FILES":
      return {
        ...state,
        files: [...payload],
      };
    case "FETCH_PATIENT_FILES":
      return {
        ...state,
        patientFiles: [...payload],
      };
    case "FETCH_THERAPIST_FILES":
      return {
        ...state,
        therapistFiles: [...payload],
      };
    case "FETCH_GROUP_FILES":
      return {
        ...state,
        groupFiles: [...payload],
      };
    case "FETCH_FILE_ATTACHMENT":
      return {
        ...state,
        attachedFiles: [...state.attachedFiles, payload],
      };
    case "ADD_THERAPIST_FILE":
      if (!state.therapistFiles) return state;
      return {
        ...state,
        therapistFiles: [payload, ...state.therapistFiles],
      };
    case "ADD_GROUP_FILES":
      return {
        ...state,
        groupFiles: [...payload, ...state.groupFiles],
      };
    case "UPDATE_THERAPIST_FILES":
      if (!state.therapistFiles) return state;
      const updatedFiles = [...state.therapistFiles];
      payload.map((item: FileResource) => {
        const indexToUpdate = state?.therapistFiles?.findIndex(
          (file) => file.uuid === item.uuid
        );
        if (indexToUpdate && indexToUpdate >= 0) {
          updatedFiles.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        therapistFiles: updatedFiles,
      };
    case "UPDATE_GROUP_FILES":
      const updatedGroupFiles = [...state.groupFiles];
      payload.map((item: FileResource) => {
        const indexToUpdate = state.groupFiles.findIndex(
          (file) => file.uuid === item.uuid
        );
        if (indexToUpdate > 0) {
          updatedGroupFiles.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        groupFiles: updatedGroupFiles,
      };
    case "UPDATE_PUBLIC_FILES":
      if (!state.files) return state;
      const updatedPublicFiles = [...state.files];
      payload.map((item: FileResource) => {
        const indexToUpdate = state?.files?.findIndex(
          (file) => file.uuid === item.uuid
        );
        if (indexToUpdate && indexToUpdate >= 0) {
          updatedPublicFiles.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        files: updatedPublicFiles,
      };
    case "DELETE_THERAPIST_FILE":
      if (!state.therapistFiles) return state;
      return {
        ...state,
        therapistFiles: state.therapistFiles.filter(
          (file) => file.uuid !== payload
        ),
      };
    case "DELETE_PUBLIC_FILE":
      if (!state.files) return state;
      return {
        ...state,
        files: state.files.filter((file) => file.uuid !== payload),
      };
    case "DELETE_GROUP_FILE":
      return {
        ...state,
        groupFiles: state.groupFiles.filter((file) => file.uuid !== payload),
      };
    case "FETCH_PATIENT_PUBLIC_FILES":
      return {
        ...state,
        patientPublicFiles: [...payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
