import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";
import {
  updateFilesAction,
  updatePublicFilesAction,
  deleteGroupFileAction,
} from "store/actions/files";

type Props = {
  fileId: string;
  patientId?: string;
  groupId?: string;
};

const useUnassignFile = ({ fileId, patientId, groupId }: Props) => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const unassignFile = () => {
    setSuccess(false);
    setLoading(true);

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                unassignFileFromPatientOrGroup(
                  fileId: ${fileId ? `"${fileId}"` : `""`}, 
                  patientId: ${patientId ? `"${patientId}"` : `""`},
                  groupId: ${groupId ? `"${groupId}"` : `""`}
                ) {
                    fileUrl
                    title
                    description
                    order
                    uuid
                    addedBy
                    sharedWith {
                        value
                        label
                    }
                  }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "unassign_file",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              setLoading(false);
              return true;
            } else {
              toast("File removed");
              resolve();
              setLoading(false);
              const data = resData.data.unassignFileFromPatientOrGroup;
              ReactGA.event("unassign_file_success", {
                user_id: user?.id,
                file: data,
              });
              dispatch(updateFilesAction([data]));
              dispatch(updatePublicFilesAction([data]));
              if (groupId) dispatch(deleteGroupFileAction(data.uuid));
              return setSuccess(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    unassignFile,
    loading,
    success,
  };
};

export default useUnassignFile;
