import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addSubmissionDraftAction,
  clearSubmissionDraftAction,
  editSubmissionAction,
} from "store/actions/submissions";
import {
  useLogout,
  useFetchSubmissions,
  useCurrentUser,
  useGetSubmissionDrafts,
} from "utils/hooks";
import { flat } from "utils/helpers";
import { FIELD_VALUES } from "utils/constants";
import { Assignment, Field, Section } from "types";

interface UseEditSubmissionProps {
  isOnSubmit?: boolean;
  currentAssignment?: Assignment;
  takeLastAsDraft?: boolean;
  privacySetting?: string | boolean;
  groupPrivacySetting?: boolean | null;
}

const useEditSubmission = ({
  isOnSubmit,
  currentAssignment,
  takeLastAsDraft,
}: UseEditSubmissionProps) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { logOut } = useLogout();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { user } = useCurrentUser();
  const { submissionDraft } = useGetSubmissionDrafts({
    assignmentId: currentAssignment?.uuid,
    takeLastAsDraft,
  });

  const saveSubmissionDraft = async (
    generatedSubmission: { assignmentId: any; assignmentLabel: any },
    concatenatedFields: any[]
  ) => {
    const generatedSubmissionWithFilteredFields = {
      submission: generatedSubmission,
      responseFields: concatenatedFields.filter(
        (response: { value: string | null }) =>
          response.value !== null && response.value !== FIELD_VALUES.SKIPPED
      ),
    };
    dispatch(addSubmissionDraftAction(generatedSubmissionWithFilteredFields));
  };

  const convertFieldToRQLFormat = (field: {
    fieldId: any;
    fieldLabel: any;
    fieldType: any;
    sectionId: any;
    sectionLabel: any;
    value: string;
  }) => {
    return `{ 
          fieldId: "${field.fieldId}",
          fieldLabel: "${field.fieldLabel}",
          fieldType: "${field.fieldType}", 
          sectionId: "${field.sectionId}",
          sectionLabel: "${field.sectionLabel}",  
          value: "${
            field?.value ? field?.value.replace(/["]/g, '\\"') : field?.value
          }" 
        }`;
  };

  const submitEdits = async (
    concatenatedFields: any[],
    submissionId: {
      submission: { assignmentId: any; assignmentLabel: any };
      responseFields: unknown[];
    },
    assignment: { label: any; uuid: any; sections: { assignmentId: any }[] }
  ) => {
    const formattedFields = concatenatedFields.map(convertFieldToRQLFormat);

    try {
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 100));
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
                mutation {
                  updateSubmission(
                    submissionId: ${submissionId}
                    responseFields: [${formattedFields}]
                  ) {
                    uuid
                    createdAt
                    slug
                    userId
                    assignmentId
                    assignmentLabel
                    privacy
                    responseFields {
                      uuid
                      submissionId
                      sectionId
                      sectionLabel
                      fieldId
                      fieldLabel
                      fieldType
                      value
                    }
                  }
                }
              `,
        }),

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (res.status !== 200 && res.status !== 201) {
        const error = await res.json();
        if (error.errors[0].message.includes("BRKN_6001")) {
          toast.warn(`For security purposes please log in again.`);
          logOut();
        } else {
          toast.error(`Hmm, something went wrong.`);
          throw new Error("Failed!");
        }
      }
      const resData = await res.json();
      if (resData.errors) {
        toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
      } else {
        fetchAllSubmissions();
        toast(`Your ${assignment.label} has been created!`);
        dispatch(editSubmissionAction(resData.data.createSubmission));
        dispatch(clearSubmissionDraftAction(assignment.uuid));
        ReactGA.event("create_submission_success", {
          user_id: user?.id,
          assignment_id: assignment.sections[0].assignmentId,
          assignment_type: assignment.label,
        });
        history("/your-submissions");
      }
    } catch (err) {
      console.log(err);
    }
  };

  interface SubmissionPrivacyProps {
    submissionId: string;
    assignment: Assignment | null;
    privacySetting?: string | boolean;
    groupPrivacySetting?: boolean | null;
  }

  const updateSubmissionPrivacy = async ({
    submissionId,
    assignment,
    privacySetting,
    groupPrivacySetting,
  }: SubmissionPrivacyProps) => {
    try {
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 100));
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
                mutation {
                  updateSubmissionPrivacy(
                    submissionId: "${submissionId}",
                    privacySetting: ${privacySetting}
                    groupPrivacySetting: ${groupPrivacySetting}
                  ) {
                    uuid
                    updatedAt
                    privacy
                    groupPrivacy
                  }
                }
              `,
        }),

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (res.status !== 200 && res.status !== 201) {
        const error = await res.json();
        if (error.errors[0].message.includes("BRKN_6001")) {
          toast.warn(`For security purposes please log in again.`);
          logOut();
        } else {
          toast.error(`Hmm, something went wrong.`);
          throw new Error("Failed!");
        }
      }
      const resData = await res.json();
      if (resData.errors) {
        toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
      } else {
        fetchAllSubmissions();
        toast(`Privacy has been updated!`);
        dispatch(editSubmissionAction(resData.data.createSubmission));
        ReactGA.event("update_submission__privacy_success", {
          user_id: user?.id,
          assignment_id: assignment?.sections[0].assignmentId,
          assignment_type: assignment?.label,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  interface FieldToServerFormatProps {
    values: any;
    section: Section;
    draft: any;
  }

  const createConverterForFieldToServerFormat =
    ({ values, section, draft }: FieldToServerFormatProps) =>
    (field: Field) => {
      // check if value is an object, like in the case of a select field
      const currentFieldValue = (fieldValue: any) => {
        if (!fieldValue) {
          return null;
        }
        if (typeof fieldValue === "object") {
          return fieldValue?.value
            ? fieldValue?.value.toString()
            : fieldValue?.toString();
        } else {
          return fieldValue && fieldValue.toString().replaceAll("\n", "\\n");
        }
      };

      const isRequired = field.required !== false;

      const getFieldValue = (fieldItem: Field) => {
        const valueFromDraft =
          draft &&
          draft.responseFields.find(
            (fieldFromDraft: { fieldId: any; value: string }) =>
              fieldFromDraft.fieldId === fieldItem.uuid &&
              fieldFromDraft.value !== FIELD_VALUES.SKIPPED
          )?.value;
        if (fieldItem.required === false) {
          return (
            currentFieldValue(values[`f_${fieldItem.uuid}`]) ||
            valueFromDraft ||
            FIELD_VALUES.SKIPPED
          );
        }
        return currentFieldValue(
          values[`f_${fieldItem.uuid}`] || valueFromDraft
        );
      };

      return {
        fieldId: field.uuid,
        fieldLabel: field.label,
        fieldType: field.type,
        sectionId: field.sectionId,
        sectionLabel: section.label,
        value: getFieldValue(field),
        required: isRequired,
      };
    };

  function sendDraftAnalytics(assignment: {
    sections: { assignmentId: any }[];
    label: any;
  }) {
    ReactGA.event("create_draft_submission_success", {
      user_id: user?.id,
      assignment_id: assignment.sections[0].assignmentId,
      assignment_type: assignment.label,
    });
  }

  interface HandleSectionSubmissionUpdateProps {
    assignment: Assignment;
    values: any;
    isDraft?: boolean;
  }

  const handleSectionSubmissionUpdate = ({
    assignment,
    values,
    isDraft = false,
  }: HandleSectionSubmissionUpdateProps) => {
    const generatedSubmission = {
      assignmentId: assignment.sections[0].assignmentId,
      assignmentLabel: assignment.label,
    };
    const allFields = assignment.sections.map((section: Section) =>
      section.fields.map(
        createConverterForFieldToServerFormat({
          values: values,
          section: section,
          draft: submissionDraft,
        })
      )
    );
    const concatenatedFields = flat(allFields);

    const isMissingValues = concatenatedFields.find(
      (response: any) => response.required && !response.value
    );

    if (isMissingValues || isDraft) {
      if (!isDraft) {
        // Exclude automatic save from analytics
        sendDraftAnalytics(assignment);
      }
      // only updated filled fields in draft
      return saveSubmissionDraft(generatedSubmission, concatenatedFields);
    } else {
      if (isOnSubmit) {
        const generatedSubmissionWithFields = {
          submission: generatedSubmission,
          responseFields: concatenatedFields,
        };
        return submitEdits(
          concatenatedFields,
          generatedSubmissionWithFields,
          assignment
        );
      }
    }
    return Promise.resolve();
  };

  return {
    handleSectionSubmissionUpdate,
    updateSubmissionPrivacy,
  };
};

export default useEditSubmission;
