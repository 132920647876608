import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout, useFetchPractices } from "utils/hooks";
import { updatePracticeAction } from "store/actions/practices";
import { formatInput } from "utils/helpers";

const useUpdatePractice = (practiceId: string | null) => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const { fetchPractices } = useFetchPractices();

  const submitUpdatePractice = async (args: any) => {
    if (!args || !practiceId) {
      return null;
    }

    const formatMultiSelectOptions = (selections: any) => {
      if (!selections) {
        return null;
      }
      return selections?.map((selection: any) => {
        return selection?.value;
      });
    };

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              updatePractice(practice: {
                uuid: "${practiceId}",
                practiceName: "${formatInput(args.practiceName)}",
                treatmentType: "${formatMultiSelectOptions(
                  args.treatmentType
                )}",
                practiceSize: "${args.practiceSize?.value}",
                address1: "${formatInput(args.address1)}",
                address2: "${formatInput(args.address2)}",
                state: "${formatInput(args.state?.value)}",
                zipcode: "${formatInput(args.zipcode)}",
                phone: "${formatInput(args.phone)}",
              }) {
                practiceName
                treatmentType
                practiceSize
                address1
                address2
                state
                zipcode
                phone
                slug
                organizationId
                status
                logoId
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "update_practices",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        fetchPractices();
        toast("Your practice has been updated");
        dispatch(updatePracticeAction(resData.data.updatePractice));
        ReactGA.event("practice_updated_success", {
          user_id: user?.id,
          practice_id: practiceId,
        });
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    submitUpdatePractice,
  };
};

export default useUpdatePractice;
