import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchGroupUsers } from "utils/hooks";
import { User } from "types";

interface useGetGroupUsersProps {
  groupId: string | null;
  sort?: boolean;
  userId?: string;
}

const useGetGroupUsers = ({
  groupId,
  sort = true,
  userId,
}: useGetGroupUsersProps) => {
  const { fetchGroupUsers } = useFetchGroupUsers();

  const groupUsers = useSelector((state: RootStateOrAny) => {
    return state.groups && state.groups.groupUsers;
  });

  useEffect(() => {
    fetchGroupUsers(groupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const sortByRolePriority = (users: User[]) => {
    const rolePriority: { [key: string]: number } = {
      owner: 1,
      moderator: 2,
      member: 3,
    };

    return users
      ?.slice()
      .sort(
        (a, b) =>
          rolePriority[a.role as string] - rolePriority[b.role as string]
      );
  };

  const groupUser = groupUsers?.find((user: User) => user.uuid === userId);

  return {
    groupUsers: sort ? sortByRolePriority(groupUsers) : groupUsers,
    groupUser,
  };
};

export default useGetGroupUsers;
