import "./AppLayout.styles.scss";
import React, { useEffect, ReactNode, FC, useState } from "react";
import { MobileBottomNav, SideNav, Modal } from "components";
import {
  useViewport,
  useGuestRedirect,
  useCurrentUser,
  useFetchPracticeById,
  useUpdateRemovedPracticeId,
} from "utils/hooks";
import useSubmissionUtils from "pages/AppPages/Shared/Submissions/utils";
import {
  AssignmentContext,
  useAssignmentContext,
} from "pages/AppPages/PatientPages/AssignmentContextWrapper/useAssignmentContext";
import { useTopNavHeight } from "components/layout/TopNav/TopNavHeightContext";
import PracticeCreateConfirmationModal from "./components/PracticeCreateConfirmationModal/PracticeCreateConfirmationModal";
import { Practice } from "types";
import { useTranslation } from "react-i18next";

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { redirect } = useGuestRedirect();
  const { isMobile } = useViewport();
  const { topNavHeight } = useTopNavHeight();
  const { isVerified, isAuthenticated, user } = useCurrentUser();
  const { fetchPracticeById } = useFetchPracticeById();
  const { updateRemovedPracticeId } = useUpdateRemovedPracticeId();
  const { t } = useTranslation(["common"]);

  const [practice, setPractice] = useState<Practice | null>(null);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    !!user?.removedPracticeId
  );

  useEffect(() => {
    redirect();
  }, [redirect]);

  useEffect(() => {
    if (!user?.removedPracticeId) {
      return;
    }

    const fetchPractice = async () => {
      const practiceData = await fetchPracticeById(user.removedPracticeId);
      setPractice(practiceData);
    };

    fetchPractice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.removedPracticeId]);

  const MemoizedSideNav = React.memo(() => <SideNav />);
  MemoizedSideNav.displayName = "MemoizedSideNav";

  const { defaultValues } = useAssignmentContext();

  const { showConfirmEmailBar } = useSubmissionUtils();

  const showAlertBar = isAuthenticated && !isVerified && showConfirmEmailBar;

  const handleCancelCreatePractice = async () => {
    setIsCreateModalOpen(false);
    await updateRemovedPracticeId();
  };

  return (
    <AssignmentContext.Provider value={defaultValues}>
      <div className="flex">
        {isMobile ? <MobileBottomNav /> : <MemoizedSideNav />}
        <div className="flex col app-body">
          <div
            className={`app-content topnav-content ${
              showAlertBar ? "hasAlertBar" : ""
            }`}
            style={{ marginTop: topNavHeight ? topNavHeight + 10 : 0 }}
          >
            {children}
          </div>
        </div>
      </div>
      <Modal isOpen={isCreateModalOpen} onClose={handleCancelCreatePractice}>
        <PracticeCreateConfirmationModal
          practiceName={practice?.practiceName || t("practices.practice_label")}
          onCancel={handleCancelCreatePractice}
        />
      </Modal>
    </AssignmentContext.Provider>
  );
};

export default AppLayout;
