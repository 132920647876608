/* eslint-disable react/display-name */
import "./GeneratedField.styles.scss";
import React, { useContext } from "react";
import StringField from "./components/StringField";
import RangeField from "./components/RangeField";
import TextareaField from "./components/TextareaField";
import DateField from "./components/DateField";
import TimeField from "./components/TimeField";
import SelectField from "./components/SelectField";
import MoodScaleField from "./components/MoodScaleField";
import InputListField from "./components/InputListField";
import MultiValueField from "./components/MultiValueField";
import FileAttachmentField from "./components/FileAttachmentField/FileAttachmentField";
import ParagraphField from "./components/ParagraphField/ParagraphField";
import HeadingField from "./components/HeadingField/HeadingField";
import VideoField from "./components/VideoField/VideoField";
import SingleSelectField from "./components/SingleSelectField";
import MultiResponseField from "./components/MultiResponseField";
import ImageField from "./components/ImageField";
import {
  CheckboxGrid,
  InfoBoxDisplayField,
  RichTextDisplayField,
} from "components";
import CustomMoodScaleField from "../../../pages/AppPages/PatientPages/AssignmentsForPatients/fields/fieldComponents/CustomMoodScaleField";
import { AssignmentBuilderContext } from "pages/AppPages/TherapistPages/AssignmentBuilder/useAssignmentBuilderContext";
import { Field } from "types";

interface Props {
  field: Field;
  readonly?: boolean;
  showLabelForBuilder?: boolean;
  index?: number;
}

const GeneratedField = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  field: { sectionId, ...field },
  readonly = false,
  showLabelForBuilder,
  index,
}: Props) => {
  const { validationErrors } = useContext(AssignmentBuilderContext);
  let FieldComponent = () => <></>;
  switch (field.type) {
    case "STRING":
      FieldComponent = () => (
        <StringField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          inputType="text"
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
      break;
    case "DATE":
      FieldComponent = () => (
        <DateField
          id={field.uuid}
          inputName={field.uuid}
          inputLabel={field.label}
          inputType="date"
          readOnly={readonly}
          {...field}
        />
      );
      break;
    case "TIME":
      FieldComponent = () => (
        <TimeField
          id={field.uuid}
          inputName={field.uuid}
          inputLabel={field.label}
          inputType="time"
          readOnly={readonly}
          {...field}
        />
      );
      break;
    case "SCALE":
      FieldComponent = () => (
        <RangeField
          name={field.uuid || field.name}
          label={field.label}
          min={field.min}
          max={field.max}
          maxLabel={field.maxLabel}
          minLabel={field.minLabel}
          scoreMappings={field.scoreMappings}
          defaultValue={field.defaultValue}
          readOnly={readonly}
          hint={field.hint}
        />
      );
      break;
    case "CHECKBOX_GRID":
      FieldComponent = () => (
        <CheckboxGrid
          {...field}
          label={field.label}
          description={field.hint}
          allowMulti={field.allowMulti}
          checkboxes={field.checkboxOptions}
          checkboxRows={field.checkboxRows}
          sectionId={sectionId}
        />
      );
      break;
    case "SELECT":
      FieldComponent = () => (
        <SelectField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          choicesKey={field.choicesKey}
          options={field.options}
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
      break;
    case "SELECT_FIELD":
      FieldComponent = () => (
        <SingleSelectField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          options={field.options}
          sectionId={sectionId}
        />
      );
      break;
    case "MULTI_SELECT":
      FieldComponent = () => (
        <StringField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
      break;
    case "TEXTAREA":
      FieldComponent = () => (
        <TextareaField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
      break;
    case "MOOD_SCALE":
      FieldComponent = () => (
        <MoodScaleField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
      break;
    case "CUSTOM_MOOD_SCALE":
      FieldComponent = () => (
        <>
          <CustomMoodScaleField
            field={{ ...field, sectionId }}
            defaultValue={null}
            onChange={() => null}
          />
          {field.hint && field.hint !== "null" && (
            <p className="mood-scale-field__hint mt-2">
              <small>{field.hint}</small>
            </p>
          )}
        </>
      );
      break;
    case "INPUT_LIST":
      FieldComponent = () => (
        <InputListField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          sectionId={sectionId}
          value={field.value}
        />
      );
      break;
    case "MULTI_RESPONSE":
      FieldComponent = () => (
        <MultiResponseField
          id={field.uuid}
          name={field.uuid}
          label={field.label}
          readOnly={readonly}
          field={{ ...field, sectionId }}
          onChange={() => {
            return null;
          }}
        />
      );
      break;
    case "MULTI_VALUE":
      FieldComponent = () => (
        <MultiValueField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
      break;
    case "FILE_ATTACHMENT":
      FieldComponent = () => (
        <FileAttachmentField
          uuid={field.uuid}
          label={field.label}
          fileId={field.fileId}
          autoSave={true}
          field={field}
          validationError={
            validationErrors && validationErrors[`FILE_ATTACHMENT${index}`]
          }
        />
      );
      break;
    case "VIDEO_EMBED":
      FieldComponent = () => (
        <VideoField
          uuid={field.uuid}
          label={field.label}
          field={{ ...field, sectionId }}
          videoURL={field.videoURL}
          validationError={
            validationErrors && validationErrors[`VIDEO_EMBED${index}`]
          }
        />
      );
      break;
    case "PARAGRAPH_FIELD":
      FieldComponent = () => (
        <ParagraphField
          {...field}
          uuid={field.uuid}
          label={field.label}
          inlineValue={field.inlineValue}
          showLabelForBuilder={showLabelForBuilder}
        />
      );
      break;
    case "INFO_BOX":
      FieldComponent = () => (
        <>
          <div className="mb-2 pb-3">
            <InfoBoxDisplayField
              {...field}
              value={field.richText || undefined}
              backgroundColor={field.colorTheme}
              icon={field.icon}
              iconAlignment={field.iconAlignment}
            />
          </div>
        </>
      );
      break;
    case "HEADING_FIELD":
      FieldComponent = () => (
        <HeadingField
          {...field}
          uuid={field.uuid}
          label={field.label}
          inlineValue={field.inlineValue}
          showLabelForBuilder={showLabelForBuilder}
        />
      );
      break;
    case "RICH_TEXT":
      FieldComponent = () => (
        <>
          <div className="mb-2 pb-3">
            <RichTextDisplayField value={field.richText} />
            {!field.richText && (
              <>
                <p className="rich-text-label">{field.label}</p>
                <small>
                  <i>Add content in field settings</i>
                </small>
              </>
            )}
          </div>
        </>
      );
      break;
    case "IMAGE":
      FieldComponent = () => (
        <ImageField
          uuid={field.uuid}
          label={field.label}
          fileUrl={field.fileUrl}
          field={{ ...field, sectionId }}
          validationError={
            validationErrors && validationErrors[`IMAGE${index}`]
          }
        />
      );
      break;
    case "THOUGHT_DISTORTIONS_FIELD":
      FieldComponent = () => (
        <SelectField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          choicesKey="THOUGHT_DISTORTIONS"
          readOnly={readonly}
          type={field.type}
          sectionId={sectionId}
        />
      );
      break;
    case "NEGATIVE_EMOTIONS_FIELD":
      FieldComponent = () => (
        <SelectField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          choicesKey="NEGATIVE_EMOTIONS"
          readOnly={readonly}
          type={field.type}
          sectionId={sectionId}
        />
      );
      break;
    default:
      FieldComponent = () => (
        <StringField
          {...field}
          id={field.uuid}
          name={field.uuid ? field.uuid : field.name}
          label={field.label}
          readOnly={readonly}
          sectionId={sectionId}
        />
      );
  }
  return <FieldComponent />;
};

export default GeneratedField;
