import ReactDOM from "react-dom";
import "./GroupWorkbook.styles.scss";
import React, { useEffect, useState } from "react";
import {
  useGetAssignments,
  useGetGroups,
  useQueryParams,
  useGetSubmissions,
  useFetchAssignments,
  useUpdateAssignment,
  useUpdateNotification,
} from "utils/hooks";
import {
  PageActionBar,
  AssignmentCard,
  AssignmentIcon,
  SidePanel,
  Modal,
  PageLoading,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import AddAssignmentsButton from "pages/AppPages/TherapistPages/SingleGroup/components/GroupWorkbook/components/AddAssignmentsButton";
import { ASSIGNMENT_STATUSES } from "utils/constants";
import useAssignmentDetailsTabs from "./components/AssignmentDetails/useAssignmentDetailsTabs";
import AssignmentSettings from "./components/AssignmentDetails/AssignmentSettings/AssignmentSettings";
import AssignmentFields from "./components/AssignmentDetails/AssignmentFields";
import WorkbookEmptyState from "./components/WorkbookEmptyState";
import ReminderDetails from "./components/ReminderDetails";
import DeleteAssignmentConfirmationModal from "./components/AssignmentDetails/AssignmentSettings/DeleteAssignmentConfirmationModal";
import { toAmericanDate, toUnixTimestamp, isGroupActive } from "utils/helpers";
import { Assignment } from "types";
import ConfigureAssignment from "pages/AppPages/TherapistPages/SinglePatient/components/PatientWorkbook/components/ConfigureAssignment";
import SaveAssignmentConfigureModal from "pages/AppPages/TherapistPages/SinglePatient/components/PatientWorkbook/components/ConfigureAssignment/components/SaveAssignmentConfigureModal";
interface PortaledModalProps {
  component: React.ReactNode;
}

const PortaledModal: React.FC<PortaledModalProps> = ({ component }) => {
  const modalRoot = document.getElementById("root");

  return modalRoot ? ReactDOM.createPortal(component, modalRoot) : null;
};

const GroupWorkbook: React.FC = () => {
  const { groupStatus, uuid } = useParams<{
    groupStatus: string;
    uuid: string;
  }>();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const history = useNavigate();
  const { fetchGroupAssignments } = useFetchAssignments();

  const { hasUrlParams } = useQueryParams();
  const isFromNewAssignment = hasUrlParams("assignment-added");
  const assignmentSlug = hasUrlParams("assignment");

  const { groupAssignments } = useGetAssignments({ groupId: group.uuid });
  const { groupSubmissions } = useGetSubmissions({ groupId: group.uuid });
  const { unsavedReminderSettings, updateUnsavedRemindSettings } =
    useUpdateNotification();
  const { unsavedConfigureSettings, updateUnsavedConfigSettings } =
    useUpdateAssignment();

  const [currentAssignment, setCurrentAssignment] = useState<any>(null);
  const [showAssignmentPanel, setShowAssignmentPanel] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState<any>(null);
  const [sidePanelTitle, setSidePanelTitle] = useState<string | undefined>();
  const [askToSaveSettings, setAskToSaveSettings] = useState<boolean>(false);
  const [canSaveSettings, setCanSaveSettings] = useState<boolean>(false);

  const { assignmentDetailTabs, activeTab, handleTabChange } =
    useAssignmentDetailsTabs();

  useEffect(() => {
    if (unsavedReminderSettings?.length === 0 && !unsavedConfigureSettings) {
      setCanSaveSettings(false);
      setAskToSaveSettings(false);
    }
  }, [unsavedReminderSettings, unsavedConfigureSettings]);

  const handleAssignmentPanelClose = () => {
    if (unsavedConfigureSettings || unsavedReminderSettings?.length !== 0) {
      setAskToSaveSettings(true);
    } else {
      setIsDeleteModalOpen(false);
      setCurrentAssignment(null);
      setShowAssignmentPanel(false);
      history(`/groups/${groupStatus}/${group?.slug}/workbook`);
    }
  };

  const visibleAssignments = groupAssignments
    ?.filter(
      ({ status, type }: any) =>
        status === ASSIGNMENT_STATUSES.VISIBLE && type !== "SAFETY_PLAN"
    )
    .slice()
    .sort(
      (a: { createdAt: number }, b: { createdAt: number }) =>
        b.createdAt - a.createdAt
    );

  useEffect(() => {
    fetchGroupAssignments(group.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  useEffect(() => {
    if (isFromNewAssignment && assignmentSlug) {
      const assignmentFromSlug = groupAssignments?.find(
        (a: { slug: string }) => a.slug === assignmentSlug
      );
      setCurrentAssignment(assignmentFromSlug || null);
      setShowAssignmentPanel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromNewAssignment, assignmentSlug]);

  const constructSidePanel = (
    tabNumber: number,
    assignment: Assignment,
    title: string
  ) => {
    handleTabChange(assignmentDetailTabs[tabNumber]);
    setCurrentAssignment(assignment);
    setShowAssignmentPanel(true);
    setSidePanelTitle(title);
  };

  const handleCloseConfigurePanelWithoutSaving = () => {
    if (unsavedReminderSettings?.length !== 0) {
      updateUnsavedRemindSettings([]);
    }
    if (unsavedConfigureSettings) {
      updateUnsavedConfigSettings(false);
    }
    setAskToSaveSettings(false);
    setIsDeleteModalOpen(false);
    setShowAssignmentPanel(false);
    history(`/groups/${groupStatus}/${group?.slug}/workbook`);
  };

  return (
    <>
      {visibleAssignments ? (
        <div className="workbook-page">
          {isGroupActive(group) && (
            <PageActionBar>
              <div className="workbook-page-header">
                <AddAssignmentsButton groupSlug={group?.slug} />
              </div>
            </PageActionBar>
          )}

          <div className="workbook-page-body">
            <div className="workbook-page-section">
              {visibleAssignments?.length === 0 ? (
                <WorkbookEmptyState group={group} />
              ) : (
                <>
                  {visibleAssignments?.map((assignment: Assignment) => {
                    const { notification } = assignment;

                    const notificationsLive = notification?.status === "LIVE";
                    const notificationEndTimestamp =
                      notification?.endDate && notification.endDate !== "null"
                        ? toUnixTimestamp(notification.endDate)
                        : null;

                    const now =
                      toUnixTimestamp(new Date()) || Number.MAX_SAFE_INTEGER;
                    const notificationsExpired =
                      notificationEndTimestamp !== null
                        ? notificationEndTimestamp < now
                        : false;

                    return (
                      <AssignmentCard
                        category={assignment.category}
                        isExpired={notificationsExpired}
                        isActive={false}
                        showSubtitle={notificationsLive}
                        subtitle={
                          notificationsLive && !notificationsExpired ? (
                            <ReminderDetails notification={notification} />
                          ) : notificationsExpired ? (
                            <span>
                              Completed: {toAmericanDate(notification?.endDate)}
                            </span>
                          ) : null
                        }
                        key={assignment.slug}
                        assignment={assignment}
                        submissions={groupSubmissions}
                        onRemoveClick={() => {
                          setAssignmentToDelete(assignment);
                          setIsDeleteModalOpen(true);
                        }}
                        onScheduleClick={() => {
                          constructSidePanel(0, assignment, "");
                        }}
                        onConfigureClick={() => {
                          constructSidePanel(
                            1,
                            assignment,
                            "Configure labels and visibility"
                          );
                        }}
                        onPreviewClick={() => {
                          constructSidePanel(2, assignment, "Preview");
                        }}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <PageLoading vh50 />
      )}

      <SidePanel
        title={
          <div className="group-workbook-panel-tabs">
            <AssignmentIcon
              icon={currentAssignment?.icon?.src}
              style={currentAssignment?.type}
              color={currentAssignment?.color}
            />
            <h4 className="side-panel__header-title">
              {currentAssignment?.label}
            </h4>
          </div>
        }
        isVisible={showAssignmentPanel}
        onClose={handleAssignmentPanelClose}
      >
        {sidePanelTitle && (
          <h1 className="side-panel_title">{sidePanelTitle}</h1>
        )}
        <>
          {activeTab.name === "Schedule" && (
            <AssignmentSettings
              assignment={currentAssignment}
              onClose={handleAssignmentPanelClose}
              canSaveSettings={canSaveSettings}
              setCanSaveSettings={setCanSaveSettings}
            />
          )}
          {activeTab.name === "Configure" && (
            <ConfigureAssignment
              assignment={currentAssignment}
              canSaveSettings={canSaveSettings}
              setCanSaveSettings={setCanSaveSettings}
              isGroup={true}
            />
          )}
          {activeTab.name === "Preview" && (
            <AssignmentFields
              assignment={currentAssignment}
              panel={"Preview"}
            />
          )}
        </>
      </SidePanel>

      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteAssignmentConfirmationModal
              assignment={assignmentToDelete}
              onCancel={() => setIsDeleteModalOpen(false)}
            />
          </Modal>
        }
      />

      <PortaledModal
        component={
          <Modal
            isOpen={askToSaveSettings}
            onClose={() => setAskToSaveSettings(false)}
          >
            <SaveAssignmentConfigureModal
              onCancel={() => {
                handleCloseConfigurePanelWithoutSaving();
              }}
              onConfirm={() => {
                setCanSaveSettings(true);
                setAskToSaveSettings(false);
              }}
            />
          </Modal>
        }
      />
    </>
  );
};

export default GroupWorkbook;
