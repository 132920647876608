import React from "react";
import CollectionCard from "components/elements/CollectionCard";
import { Collection } from "types";
import { getCollectionImage } from "../../utils";

type Props = {
  collections: Array<Collection> | [];
  selectedCollections?: Array<Collection> | [];
  handleSelectResource: any;
};
const CollectionsForSelect = ({
  collections,
  selectedCollections,
  handleSelectResource,
}: Props) => {
  return (
    <>
      {collections.map((collection) => {
        const selected =
          selectedCollections &&
          selectedCollections.find((c) => collection.uuid === c.uuid);
        return (
          <div
            key={collection.uuid}
            className={`bottom-modal_resource ${
              selected ? "bottom-modal_selected" : ""
            }`}
            role="button"
            tabIndex={0}
            onClickCapture={(e) => handleSelectResource({ collection, e })}
          >
            <CollectionCard
              collection={collection}
              collectionId={collection.uuid}
              title={collection.title}
              items={collection.collectionResources.length}
              imageSrc={String(getCollectionImage(collection))}
              description={collection.description}
              selected={selected}
            />
          </div>
        );
      })}
    </>
  );
};

export default CollectionsForSelect;
