import "./PageLoading.styles.scss";
import Loader from "assets/icons/logo-icon.svg";
import { Icon } from "components";
import React from "react";

type PageLoadingProps = {
  vh50?: boolean;
};

const PageLoading = ({ vh50 }: PageLoadingProps) => {
  return (
    <div className={`page-loading__wrapper ${vh50 ? "vh50" : ""}`}>
      <div className="page-loading__loader">
        <Icon src={Loader} />
      </div>
      <div className="page-loading__text">Loading...</div>
    </div>
  );
};

export default PageLoading;
