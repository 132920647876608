import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton, Toggle, Input, SimpleSelect } from "components";
import TrashIcon from "assets/icons/trash.svg";
import UpArrow from "assets/icons/square-up.svg";
import DownArrow from "assets/icons/square-down.svg";
import { v4 as uuid } from "uuid";
import "./CheckboxGridOptions.styles.scss";
import { CheckboxOption, CheckboxRow } from "types";

interface CheckboxGridOptionsProps {
  options?: CheckboxOption[];
  rows?: CheckboxRow[];
  fieldSetOptions?: { label: string; value: string }[];
  onChange: (options: CheckboxOption[]) => void;
  onRowsChange: (rows: CheckboxRow[]) => void;
  onAllowMultiChange: (allow: boolean) => void;
  onIncludeCheckBoxesScoreChange: (include: boolean) => void;
  onFieldSetChange: (fieldSetId: string) => void;
  allowMulti: boolean;
  showCheckboxScoring: boolean;
  defaultFieldSetId?: string;
  showFieldsets?: boolean;
}

const CheckboxGridOptions = ({
  options = [],
  rows = [],
  fieldSetOptions = [],
  onChange,
  onRowsChange,
  onAllowMultiChange,
  onIncludeCheckBoxesScoreChange,
  onFieldSetChange,
  allowMulti,
  showCheckboxScoring = false,
  defaultFieldSetId,
  showFieldsets,
}: CheckboxGridOptionsProps) => {
  const [newOptions, setNewOptions] = useState<CheckboxOption[]>([...options]);
  const [newRows, setNewRows] = useState<CheckboxRow[]>([...rows]);
  const [showScoring, setShowScoring] = useState<boolean>(showCheckboxScoring);
  const [currentAllowMulti, setCurrentAllowMulti] = useState<boolean>(
    allowMulti || false
  );
  const [currentFieldSetId, setCurrentFieldSetId] = useState<
    string | undefined
  >(defaultFieldSetId);
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    setNewOptions([...options]);
  }, [options]);

  useEffect(() => {
    setNewRows([...rows]);
  }, [rows]);

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, newOptions.length);
  }, [newOptions]);

  const handleFieldSetChange = (value: string) => {
    setCurrentFieldSetId(value);
    onFieldSetChange(value);
  };

  const addOption = () => {
    const firstBlankIndex = newOptions.findIndex(
      (option) => option.label.trim() === "" || option?.value?.trim() === ""
    );

    if (firstBlankIndex !== -1) {
      inputsRef.current[firstBlankIndex]?.focus();
    } else {
      const newOption: CheckboxOption = {
        id: uuid(),
        label: "",
        value: "",
        score: showScoring ? 0 : undefined,
        order: newOptions.length,
      };
      const updatedOptions = [...newOptions, newOption];
      setNewOptions(updatedOptions);
      onChange(updatedOptions);
    }
  };

  const addRow = () => {
    const newRow: CheckboxRow = {
      id: uuid(),
      label: `Row ${newRows.length + 1}`,
      description: "",
      order: newRows.length,
      selectedCheckboxOptions: [],
    };
    const updatedRows = [...newRows, newRow];
    setNewRows(updatedRows);
    onRowsChange(updatedRows);
  };

  const deleteOption = (index: number) => {
    if (newOptions.length === 1) return;
    const updatedOptions = newOptions.filter((_, idx) => idx !== index);
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const deleteRow = (index: number) => {
    if (newRows.length === 1) return;
    const updatedRows = newRows.filter((_, idx) => idx !== index);
    setNewRows(updatedRows);
    onRowsChange(updatedRows);
  };

  const moveUp = (index: number, isRow: boolean) => {
    if (isRow) {
      if (index === 0) return;
      const updatedRows = [...newRows];
      [updatedRows[index], updatedRows[index - 1]] = [
        updatedRows[index - 1],
        updatedRows[index],
      ];
      setNewRows(updatedRows);
      onRowsChange(updatedRows);
    } else {
      if (index === 0) return;
      const updatedOptions = [...newOptions];
      [updatedOptions[index], updatedOptions[index - 1]] = [
        updatedOptions[index - 1],
        updatedOptions[index],
      ];
      setNewOptions(updatedOptions);
      onChange(updatedOptions);
    }
  };

  const moveDown = (index: number, isRow: boolean) => {
    if (isRow) {
      if (index === newRows.length - 1) return;
      const updatedRows = [...newRows];
      [updatedRows[index], updatedRows[index + 1]] = [
        updatedRows[index + 1],
        updatedRows[index],
      ];
      setNewRows(updatedRows);
      onRowsChange(updatedRows);
    } else {
      if (index === newOptions.length - 1) return;
      const updatedOptions = [...newOptions];
      [updatedOptions[index], updatedOptions[index + 1]] = [
        updatedOptions[index + 1],
        updatedOptions[index],
      ];
      setNewOptions(updatedOptions);
      onChange(updatedOptions);
    }
  };

  const handleOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: "label" | "value" | "score"
  ) => {
    const value = key === "score" ? Number(e.target.value) : e.target.value;
    const updatedOptions = newOptions.map((option, idx) =>
      idx === index ? { ...option, [key]: value } : option
    );
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleRowChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: "label" | "description"
  ) => {
    const updatedRows = newRows.map((row, idx) =>
      idx === index ? { ...row, [key]: e.target.value } : row
    );
    setNewRows(updatedRows);
    onRowsChange(updatedRows);
  };

  return (
    <div className="checkbox-grid-options">
      <div className="checkbox-grid-options__toggle">
        <span>Include in score calculation</span>
        <Toggle
          name="showScoring"
          checked={showScoring}
          onChange={(e) => {
            setShowScoring(e.target.checked);
            onIncludeCheckBoxesScoreChange(e.target.checked);
          }}
          onText="Yes"
          offText="No"
        />
      </div>

      {showScoring && showFieldsets && fieldSetOptions.length > 0 && (
        <div className="mb-2">
          <SimpleSelect
            label="Select FieldSet"
            hint="Choose a FieldSet for scoring"
            options={fieldSetOptions}
            defaultValue={currentFieldSetId}
            onChange={handleFieldSetChange}
          />
        </div>
      )}

      <div className="checkbox-grid-options__toggle">
        <span>Allow multiple responses</span>
        <Toggle
          name="allowMulti"
          checked={currentAllowMulti}
          onChange={(e) => {
            setCurrentAllowMulti(e.target.checked);
            onAllowMultiChange(e.target.checked);
          }}
          onText="Yes"
          offText="No"
        />
      </div>
      <div className="input__label mt-4">Questions</div>
      <p className="input__hint">
        <small>
          Define the questions for each row of this assessment field
        </small>
      </p>
      <div className="rows-list">
        {newRows.map((row, index) => (
          <div key={row.id} className="rows-list__item">
            <div className="rows-list__move-controls">
              <IconButton icon={UpArrow} onClick={() => moveUp(index, true)} />
              <IconButton
                icon={DownArrow}
                onClick={() => moveDown(index, true)}
              />
            </div>
            <Input
              label={`Question ${index + 1}`}
              value={row.label}
              onChange={(e) => handleRowChange(e, index, "label")}
            />
            <IconButton icon={TrashIcon} onClick={() => deleteRow(index)} />
          </div>
        ))}
        <div className="rows-list__add-button">
          <Button
            name={"rows_list_add-button"}
            value={"Add Question"}
            onClick={addRow}
          />
        </div>
      </div>
      <div className="input__label mt-4">Options to Select</div>
      <p className="input__hint">
        <small>Enter the options patients can select from</small>
      </p>
      <div className="options-list">
        {newOptions.map((option, index) => (
          <div key={option.id} className="options-list__item">
            <div className="options-list__move-controls">
              <IconButton icon={UpArrow} onClick={() => moveUp(index, false)} />
              <IconButton
                icon={DownArrow}
                onClick={() => moveDown(index, false)}
              />
            </div>
            <input
              className="input__field"
              type="text"
              placeholder={`Option ${index + 1}`}
              value={option.label}
              onChange={(e) => handleOptionChange(e, index, "label")}
              ref={(el) => (inputsRef.current[index] = el)}
            />
            {showScoring && (
              <Input
                label="Score"
                type="number"
                value={option.score || 0}
                onChange={(e) => handleOptionChange(e, index, "score")}
              />
            )}
            <IconButton icon={TrashIcon} onClick={() => deleteOption(index)} />
          </div>
        ))}
        <div className="options-list__add-button">
          <Button
            name={"options_list_add-button"}
            value={"Add Option"}
            onClick={addOption}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckboxGridOptions;
