import React from "react";
import "./CheckboxGridResponse.styles.scss";
import { useCurrentUser } from "utils/hooks";
import Icon from "components/utility/Icon";
import { SelectedCheckboxOption } from "types";

interface CheckboxRow {
  id: string;
  label: string;
  description?: string;
  order: number;
  selectedCheckboxOptions: SelectedCheckboxOption[];
}

interface CheckboxOption {
  id: string;
  label: string;
  score?: number;
}

interface CheckboxGridResponseProps {
  choices: string; // Expecting a stringified JSON with rows and options
  layout?: string;
}

const CheckboxGridResponse: React.FC<CheckboxGridResponseProps> = ({
  choices,
  layout = "vertical",
}) => {
  const { isPatient } = useCurrentUser();

  let parsedData;
  try {
    parsedData = JSON.parse(choices) as {
      rows: CheckboxRow[];
      options: CheckboxOption[];
    };
  } catch (error) {
    console.error("Error parsing choices JSON:", error);
    parsedData = { rows: [], options: [] }; // Fallback to avoid crashes
  }

  const { rows, options } = parsedData;

  return (
    <div className="checkbox-grid-response">
      {rows?.map((row: CheckboxRow) => {
        // Get the selected options for this row based on `checkedId`
        const selectedOptions = row.selectedCheckboxOptions
          ?.map((selected) =>
            options.find(
              (option: CheckboxOption) => option.id === selected.checkedId
            )
          )
          .filter((option): option is CheckboxOption => option !== undefined);

        // Combine labels of selected options
        const selectedLabels = selectedOptions
          ?.map((option) => option.label)
          .join(", ");

        return (
          <div
            key={row.id}
            className={`checkbox-grid-response__row ${
              isPatient || layout === "vertical" ? "" : "horizontal"
            }`}
          >
            <div className="checkbox-grid-response__row-label">{row.label}</div>
            {row.description && (
              <div className="checkbox-grid-response__row-description">
                {row.description}
              </div>
            )}
            <div className="checkbox-grid-response__choices-wrapper">
              <div className="checkbox-grid-response__choices">
                {options?.map((option: CheckboxOption) => {
                  const isChecked = row.selectedCheckboxOptions?.some(
                    (selected) => selected.checkedId === option.id
                  );
                  return (
                    <div
                      key={option.id}
                      className={`checkbox-grid-response__circle ${
                        isChecked
                          ? "checkbox-grid-response__circle--selected"
                          : ""
                      }`}
                    >
                      {isPatient ? (
                        <Icon src={"Check"} />
                      ) : (
                        <>{isChecked ? option.score ?? "" : ""}</>
                      )}
                    </div>
                  );
                })}
              </div>
              {selectedOptions?.length > 0 && (
                <p className="checkbox-grid-response__selected-labels">
                  {selectedLabels}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxGridResponse;
