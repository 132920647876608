import { RootStateOrAny, useSelector } from "react-redux";

interface useGetPatientsProps {
  slug?: string;
  email?: string;
  patientId?: string;
}

const useGetPatients = ({ slug, email, patientId }: useGetPatientsProps) => {
  const patients = useSelector(
    (state: RootStateOrAny) => state.patients && state.patients
  );

  const patient = patients?.patients?.find(
    (patient: any) =>
      (patient?.email === email && email) ||
      (patient?.slug === slug && slug) ||
      (patient?.uuid === patientId && patientId)
  );

  return {
    patients: patients?.patients,
    patient: patient || null,
  };
};

export default useGetPatients;
