import "./PracticeCreateConfirmationModal.styles.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon, Button } from "components";
import { useCreatePractice } from "utils/hooks";
import { useTranslation } from "react-i18next";

interface PracticeCreateConfirmationModalProps {
  practiceName: string;
  onCancel: () => void;
}

const PracticeCreateConfirmationModal = ({
  practiceName,
  onCancel,
}: PracticeCreateConfirmationModalProps) => {
  const { t } = useTranslation(["common"]);
  const { submitCreatePractice } = useCreatePractice();
  const navigate = useNavigate();

  const handleCreatePractice = () => {
    submitCreatePractice({
      practiceName: "",
      treatmentType: null,
      practiceSize: null,
      address1: "",
      address2: "",
      state: null,
      zipcode: "",
      phone: "",
    });
    onCancel();
    navigate("/my-practice/practice-info");
  };

  return (
    <div className="practice-create-confirmation">
      <div className="practice-create-confirmation__icon">
        <Icon src="Warning" />
      </div>
      <div className="practice-create-confirmation__title">
        <div>
          {t("practices.create_practice_modal.title1", {
            practiceName,
          })}
        </div>
        <div>{t("practices.create_practice_modal.title2")}</div>
      </div>
      <div className="practice-create-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_create_practice_button"
          value={t("practices.create_practice_modal.not_now_btn")}
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={handleCreatePractice}
          name="confirm_create_practice_button"
          value={t("practices.create_practice_modal.create_practice_btn")}
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default PracticeCreateConfirmationModal;
