import "./GroupNotes.styles.scss";
import React, { ReactNode, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { PageActionBar, SidePanel, Modal, PageLoading } from "components";
import { useGetNotes, useGetGroups, useFetchNotes } from "utils/hooks";
import { useParams } from "react-router-dom";
import NotesTable from "./components/NotesTable";
import useNotesDataColumns from "./components/NotesTable/utils/useNotesDataColumns";
import AddNoteButton from "./components/AddNoteButton";
import GroupNotesEmptyState from "./components/GroupNotesEmptyState";
import NoteForm from "./components/NoteSidePanel/NoteForm";
import { Note } from "types";
import { isGroupActive } from "utils/helpers";
import DeleteNoteConfirmationModal from "./components/DeleteNoteConfirmationModal";

const GroupNotes = () => {
  const { uuid } = useParams();
  const notesDataColumns = useNotesDataColumns({ handleOpenDeleteModal });
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const { groupNotes } = useGetNotes({ groupId: group.uuid });
  const { fetchGroupNotes } = useFetchNotes();
  const [showNotePanel, setShowNotePanel] = useState(false);
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleViewNote = (note: Note) => {
    setCurrentNote(note);
    setShowNotePanel(true);
  };

  const handleClosePanel = () => {
    setShowNotePanel(false);
    setCurrentNote(null);
  };

  const handleAddNewNote = () => {
    setCurrentNote(null);
    setShowNotePanel(true);
  };

  function handleOpenDeleteModal(note: Note) {
    setShowNotePanel(false);
    setCurrentNote(note);
    setIsDeleteModalOpen(true);
  }

  const sortedNotes = groupNotes
    ?.slice()
    .sort(
      (a: Note, b: Note) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );

  useEffect(() => {
    fetchGroupNotes(group.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  const PortaledModal = ({ component }: { component: ReactNode }) => {
    const modalRoot = document.getElementById("root") as HTMLElement;
    return ReactDOM.createPortal(component, modalRoot);
  };

  const handleNoteDeleted = () => {
    setIsDeleteModalOpen(false);
    handleClosePanel();
  };

  return (
    <>
      {sortedNotes ? (
        <>
          {isGroupActive(group) && (
            <PageActionBar>
              <AddNoteButton onClick={handleAddNewNote} />
            </PageActionBar>
          )}
          {sortedNotes.length > 0 ? (
            <NotesTable
              data={sortedNotes}
              notesDataColumns={notesDataColumns}
              onRowClick={handleViewNote}
              onRowHover={(note: Note) => setCurrentNote(note)}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          ) : (
            <GroupNotesEmptyState />
          )}
        </>
      ) : (
        <PageLoading vh50 />
      )}

      <SidePanel
        title={
          <div className="group-workbook-panel-tabs">
            <h4 className="side-panel__header-title">
              {currentNote ? "Edit" : "Add"} Note
            </h4>
          </div>
        }
        isVisible={showNotePanel}
        onClose={handleClosePanel}
      >
        <NoteForm
          note={currentNote}
          onSuccess={handleClosePanel}
          group={group}
        />
      </SidePanel>

      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteNoteConfirmationModal
              note={currentNote}
              onCancel={() => setIsDeleteModalOpen(false)}
              onDelete={handleNoteDeleted}
            />
          </Modal>
        }
      />
    </>
  );
};

export default GroupNotes;
