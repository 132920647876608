import { useEffect, useState, RefObject } from "react";
import { ScoreMappings } from "types";

interface UseRangeFieldProps {
  defaultValue: { value: number; score: number; textValue: string };
  min?: number;
  max?: number;
  step?: number;
  id: string;
  value: { value: number; score: number; textValue: string } | number;
  bubbleRef: RefObject<HTMLDivElement>;
  scoreMappings?: ScoreMappings[];
}

const useRangeField = ({
  value = { value: 5, score: 0, textValue: "5" },
  id,
  min = 0,
  max = 100,
  defaultValue,
  bubbleRef,
  scoreMappings = [],
}: UseRangeFieldProps) => {
  const initialValue = typeof value === "number" ? value : value?.value;
  const initialTextValue =
    typeof value === "number" ? value.toString() : value?.textValue;

  const [currentValue, setCurrentValue] = useState(
    initialValue || defaultValue?.value
  );
  const [currentScore, setCurrentScore] = useState(
    typeof value === "number" ? value : value?.score || defaultValue?.score
  );
  const [textValue, setTextValue] = useState(
    initialTextValue || defaultValue?.textValue
  );

  const parsedScoreMappings: ScoreMappings[] = Array.isArray(scoreMappings)
    ? scoreMappings
    : [];

  const getFinalValueObject = (newValue: number) => {
    if (!parsedScoreMappings || parsedScoreMappings.length === 0) {
      // Fallback: Use value for all fields if scoreMappings is missing or empty
      return {
        value: newValue,
        score: newValue,
        textValue: newValue.toString(),
      };
    }

    const matchedMapping = parsedScoreMappings.find(
      (mapping) => mapping.scoreValue === newValue
    );

    return {
      value: newValue,
      score: matchedMapping ? matchedMapping.scoreValue : newValue,
      textValue: matchedMapping
        ? matchedMapping.textValue
        : newValue.toString(),
    };
  };

  const handleValueChange = (newValue: number) => {
    const { value, score, textValue } = getFinalValueObject(newValue);
    setCurrentValue(value);
    setCurrentScore(score);
    setTextValue(textValue);
  };

  useEffect(() => {
    if (bubbleRef.current) {
      const locationValue = ((currentValue - min) * 100) / (max - min);
      bubbleRef.current.style.left = `calc(${locationValue}% + (${
        8 - locationValue * 0.15
      }px))`;
      bubbleRef.current.innerText = textValue;
    }
  }, [currentValue, textValue, id, max, min, bubbleRef]);

  return {
    currentValue,
    currentScore,
    textValue,
    handleValueChange,
    getFinalValueObject,
  };
};

export default useRangeField;
