import { useState } from "react";
import ReactGA from "react-ga4";
import { addNoteAction, addGroupNoteAction } from "store/actions/notes";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFetchNotes, useLogout, useCurrentUser } from "utils/hooks";
import { formatInput } from "utils/helpers";

interface UseAddNoteProps {
  patientId?: string;
  groupId?: string;
}

interface NoteArgs {
  date: string;
  body: string;
  referenceUrl?: string;
  category: { value: string; label: string };
  mailing: boolean;
}

const useAddNote = ({ patientId, groupId }: UseAddNoteProps) => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchNotes, fetchGroupNotes } = useFetchNotes();
  const { user } = useCurrentUser();

  const submitAddNote = (args: NoteArgs) => {
    if (!args || !args.body) {
      return null;
    }

    setLoading(true);

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addNote(note: {
                  date: "${args.date}",
                  body: "${formatInput(args.body)}",
                  referenceUrl: "${formatInput(args.referenceUrl ?? "")}",
                  patientId: "${patientId}",
                  category: "${args.category.value}",
                  mailing: ${args.mailing ? true : false}
                }) {
                  uuid
                  date
                  body
                  referenceUrl
                  patientId
                  createdAt
                  updatedAt
                  category
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "create_notes",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setLoading(false);
              return true;
            } else {
              fetchNotes();
              toast("Note Added");
              setIsSubmitSuccessful(true);
              ReactGA.event("note_added_success", {
                user_id: user?.id,
              });
              setLoading(false);
              return dispatch(addNoteAction(resData.data.addNote));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const submitAddGroupNote = (args: NoteArgs) => {
    if (!args || !args.body) {
      return null;
    }

    setLoading(true);

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addNote(note: {
                  date: "${args.date}",
                  body: "${formatInput(args.body)}",
                  referenceUrl: "${formatInput(args.referenceUrl ?? "")}"
                  groupId: "${groupId}",
                  category: "${args.category.value}",
                  mailing: ${args.mailing}
                }) {
                  uuid
                  date
                  body
                  referenceUrl
                  patientId
                  groupId
                  createdAt
                  updatedAt
                  category
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "create_notes",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setLoading(false);
              return true;
            } else {
              fetchGroupNotes(groupId);
              toast("Note Added");
              setIsSubmitSuccessful(true);
              ReactGA.event("note_added_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              setLoading(false);
              return dispatch(addGroupNoteAction(resData.data.addNote));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitAddNote,
    submitAddGroupNote,
    loading,
    isSubmitSuccessful,
  };
};

export default useAddNote;
