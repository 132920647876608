export const updateUserProfileAction = (profile) => {
  return {
    type: "UPDATE_USER_PROFILE",
    payload: profile,
  };
};

export const updatePatientReminderSettingsAction = (reminderSettings) => {
  return {
    type: "UPDATE_PATIENT_REMINDER_SETTINGS",
    payload: reminderSettings,
  };
};

export const updateRemovedPracticeIdAction = (removedPracticeId) => {
  return {
    type: "UPDATE_REMOVED_PRACTICE_ID",
    payload: removedPracticeId,
  };
};
