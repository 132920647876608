import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useFetchLibrary from "./useFetchLibrary";
import useFetchFiles from "../files/useFetchFiles";

const useGetPatientLibrary = () => {
  const dataRef = useRef(false);
  const { fetchPatientLibrary, fetchPublicPatientLibrary } = useFetchLibrary();
  const { fetchPatientFiles } = useFetchFiles();

  const resources = useSelector((state) => state.resources && state.resources);
  const files = useSelector((state) => state.files && state.files);

  useEffect(() => {
    if (dataRef.current) return;
    fetchPatientLibrary();
    fetchPublicPatientLibrary();
    fetchPatientFiles();
    dataRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    patientResources: resources?.patientResources,
    patientFiles: files?.patientFiles,
    patientPublicResources: resources?.patientPublicResources || [],
  };
};

export default useGetPatientLibrary;
