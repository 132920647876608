import { PURGE } from "redux-persist";
import { Note } from "types";

type NoteProps = {
  type: string;
  payload: any;
};

const initialState = {
  notes: null as Array<Note> | null,
  groupNotes: null as Array<Note> | null,
};

export default (state = initialState, { type, payload }: NoteProps) => {
  switch (type) {
    case "FETCH_NOTES":
      return {
        ...state,
        notes: [...payload],
      };
    case "FETCH_GROUP_NOTES":
      return {
        ...state,
        groupNotes: [...payload],
      };
    case "ADD_NOTE":
      if (!state.notes) return state;
      return {
        ...state,
        notes: [...state.notes, payload],
      };
    case "ADD_GROUP_NOTE":
      if (!state.groupNotes) return state;
      return {
        ...state,
        groupNotes: [...state.groupNotes, payload],
      };
    case "UPDATE_NOTE":
      if (!state.notes) return state;
      return {
        ...state,
        notes: state.notes.map((note) =>
          note.uuid === payload.uuid
            ? {
                ...note,
                ...payload,
              }
            : note
        ),
      };
    case "UPDATE_GROUP_NOTE":
      if (!state.groupNotes) return state;
      return {
        ...state,
        groupNotes: state.groupNotes.map((note) =>
          note.uuid === payload.uuid
            ? {
                ...note,
                ...payload,
              }
            : note
        ),
      };
    case "DELETE_NOTE":
      if (!state.notes) return state;
      return {
        ...state,
        notes: state.notes.filter((note) => note.uuid !== payload),
      };
    case "DELETE_GROUP_NOTE":
      if (!state.groupNotes) return state;
      return {
        ...state,
        groupNotes: state.groupNotes.filter((note) => note.uuid !== payload),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
