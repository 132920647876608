import React from "react";
import "./Resource.styles.scss";
import { getUrlIcon } from "../../utils";
import { Icon } from "components";
import ClampLines from "react-clamp-lines";
import { toAmericanDateFromUnix } from "utils/helpers";
import { useViewport } from "utils/hooks";
import TrashIcon from "assets/icons/trash.svg";
import EditIcon from "assets/icons/pen-to-square.svg";
import { type LibraryResource } from "types";

interface ResourceProps {
  resource: LibraryResource;
  isOwner: boolean;
  selected?: LibraryResource | undefined;
  showEditIcon?: boolean;
  showDeleteIcon?: boolean;
  onEditResource?: (resource: LibraryResource) => void;
  onOpenDeleteModal?: (resource: LibraryResource) => void;
}

const Resource = ({
  resource,
  isOwner,
  selected,
  showEditIcon = true,
  showDeleteIcon,
  onEditResource,
  onOpenDeleteModal,
}: ResourceProps) => {
  const { resourceUrl, title, description, imageUrl, updatedAt } = resource;
  const { isMobile } = useViewport();

  if (!resourceUrl) {
    return null;
  }

  return (
    <div className="resource-wrapper">
      <div className="resource-wrapper__header">
        <div
          className={
            selected
              ? "resource-wrapper__icon-checked"
              : "resource-wrapper__icon"
          }
        >
          <Icon src={selected ? "CheckBold" : getUrlIcon(resourceUrl)} />
        </div>
        <div
          className={`resource-wrapper__title-block ${
            isOwner ? "is-library-resource-owner" : ""
          }`}
        >
          <a
            href={resourceUrl}
            target="_blank"
            className="resource-wrapper__title"
            rel="noopener noreferrer"
          >
            <ClampLines
              text={title}
              id="resource-clamped-title"
              lines={2}
              ellipsis="..."
              innerElement="h3"
              buttons={false}
            />
          </a>
          <div className="resource-wrapper__actions">
            {showDeleteIcon && (
              <span
                className="resource-wrapper__action"
                onClick={() => onOpenDeleteModal && onOpenDeleteModal(resource)}
                onKeyDown={() =>
                  onOpenDeleteModal && onOpenDeleteModal(resource)
                }
                role="button"
                tabIndex={0}
              >
                <Icon src={TrashIcon} />
              </span>
            )}
            {showEditIcon && (
              <span
                className="resource-wrapper__action"
                onClick={() => onEditResource && onEditResource(resource)}
                onKeyDown={() => onEditResource && onEditResource(resource)}
                role="button"
                tabIndex={0}
              >
                <Icon src={EditIcon} />
              </span>
            )}
          </div>
          <div className="resource-wrapper__actions">
            <span
              className="resource-wrapper__action"
              onClick={() => onOpenDeleteModal && onOpenDeleteModal(resource)}
              onKeyDown={() => onOpenDeleteModal && onOpenDeleteModal(resource)}
              role="button"
              tabIndex={0}
            >
              <Icon src={TrashIcon} />
            </span>
          </div>
          <p className="resource-wrapper__date">
            {toAmericanDateFromUnix(updatedAt)}
          </p>
        </div>
      </div>
      {imageUrl && (
        <a
          href={resourceUrl}
          target="_blank"
          className="resource-wrapper__thumb"
          rel="noopener noreferrer"
        >
          <img src={imageUrl} alt={title} />
        </a>
      )}
      {description && (
        <div className="resource-wrapper__description">
          <ClampLines
            text={description}
            id="resource-clamped-description"
            lines={isMobile ? 6 : 3}
            ellipsis="..."
            innerElement="p"
            stopPropagation={true}
            buttons={false}
          />
        </div>
      )}
    </div>
  );
};

export default Resource;
