import { Checkbox } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

interface PatientSubmissionPrivacySettingProps {
  onPrivacySettingChange: (isPrivate: boolean) => void;
  isPrivate?: boolean;
  isDisabled?: boolean | null;
}

const PatientSubmissionPrivacySetting: React.FC<
  PatientSubmissionPrivacySettingProps
> = ({ onPrivacySettingChange, isPrivate, isDisabled }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Checkbox
      name="submissionPrivacy"
      label={t("patient_assignments.share_with_therapist_label")}
      ariaLabel={t("patient_assignments.share_with_therapist_label")}
      checked={!isPrivate}
      onChange={() => onPrivacySettingChange(!isPrivate)}
      variant="no-margin"
      disabled={isDisabled ? true : false}
    />
  );
};

export default PatientSubmissionPrivacySetting;
