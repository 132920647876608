import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchPracticeUsers } from "utils/hooks";
import { User } from "types";

interface useGetPracticeUsersProps {
  practiceId: string | null;
  sort?: boolean;
  userId?: string;
}

const useGetPracticeUsers = ({
  practiceId,
  sort = true,
  userId,
}: useGetPracticeUsersProps) => {
  const { fetchPracticeUsers } = useFetchPracticeUsers();

  const practiceUsers = useSelector((state: RootStateOrAny) => {
    return state.practices && state.practices.practiceUsers;
  });

  useEffect(() => {
    fetchPracticeUsers(practiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId]);

  const sortByRolePriority = (users: User[]) => {
    const rolePriority: { [key: string]: number } = {
      owner: 1,
      member: 2,
    };

    return users
      .slice()
      .sort(
        (a, b) =>
          rolePriority[a.role as string] - rolePriority[b.role as string]
      );
  };

  const practiceUser = practiceUsers.find((user: User) => user.uuid === userId);

  return {
    practiceUsers: sort ? sortByRolePriority(practiceUsers) : practiceUsers,
    practiceUser,
  };
};

export default useGetPracticeUsers;
