import { toAmericanDate, toReadableTime } from "../../../utils/helpers";
import React from "react";
import FileAttachmentFieldForDownload from "./components/FileAttachmentFieldForDownload/FileAttachmentFieldForDownload";
import VideoEmbed from "../VideoEmbed";
import { Emoji } from "../../elements/Emoji/Emoji";
import { ResponseField } from "types";
import CheckboxGridResponse from "components/elements/CheckboxGridResponse";
import RangeSliderResponse from "components/elements/RangeSliderResponse";

interface GeneratedFieldResponseProps {
  field: ResponseField;
  isTable?: boolean;
}

const GeneratedFieldResponse = ({
  field,
  isTable,
}: GeneratedFieldResponseProps) => {
  switch (field.fieldType) {
    case "DATE":
      return (
        <p className="single-submission-for-patients-page__field">
          {toAmericanDate(field.value)}
        </p>
      );
    case "TIME":
      return (
        <p className="single-submission-for-patients-page__field">
          {toReadableTime(field.value)}
        </p>
      );
    case "INPUT_LIST": {
      const values = JSON.parse(field.value || "[]");
      return (
        <ol>
          {values.map((item: string, index: number) => (
            <li key={index + 1}>{item}</li>
          ))}
        </ol>
      );
    }
    case "MULTI_VALUE": {
      const values = JSON.parse(field.value || "{}");
      return <p>{Object.values(values).join(" ")}</p>;
    }
    case "CHECKBOX_GRID": {
      return (
        <CheckboxGridResponse
          choices={field.value}
          layout={isTable ? "horizontal" : "vertical"}
        />
      );
    }
    case "SCALE": {
      return (
        <RangeSliderResponse
          value={field.value}
          showScore={field.includeInScoreCalculation}
        />
      );
    }
    case "HEADING_FIELD": {
      return null;
    }
    case "PARAGRAPH_FIELD": {
      return null;
    }
    case "INFO_BOX": {
      return null;
    }
    case "MULTI_RESPONSE": {
      const values = JSON.parse(field.value || "{}");
      return (
        <>
          <p>
            {Object.keys(values).map((key, index) => {
              return (
                <span className="flex row" key={index}>
                  <span className="mr-05">{index + 1}.</span>
                  <span>{values[key]}</span>
                </span>
              );
            })}
          </p>
        </>
      );
    }
    case "FILE_ATTACHMENT": {
      return (
        <div>
          <p>
            <b>File was shared with patient:</b>
          </p>
          <FileAttachmentFieldForDownload
            uuid={field.uuid}
            fileId={field.value}
          />
        </div>
      );
    }
    case "VIDEO_EMBED": {
      return (
        <div>
          <p>
            <b>Video{field.videoURL}:</b>
          </p>
          <VideoEmbed
            videoURL={field.videoURL || field.value}
            uuid={field.uuid}
          />
        </div>
      );
    }
    case "CUSTOM_MOOD_SCALE": {
      let emojiData;

      // Check if field.value is a non-empty string
      if (typeof field.value === "string" && field.value.trim() !== "") {
        try {
          emojiData = JSON.parse(field.value);
        } catch (error) {
          console.error("Error parsing CUSTOM_MOOD_SCALE value:", error);
          return <div>Invalid mood data</div>; // Display an error message or handle it as needed
        }
      } else if (typeof field.value === "object" && field.value !== null) {
        emojiData = field.value; // If already an object
      } else {
        return <div>No mood data available</div>; // Handle empty or unexpected value
      }

      return <Emoji emoji={emojiData} />;
    }
    default:
      return (
        <p className="single-submission-for-patients-page__field">
          {field.value}
        </p>
      );
  }
};

export default GeneratedFieldResponse;
