import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FileResource } from "types";
import { useCurrentUser, useGetPatients, useGetGroups } from "utils/hooks";
import { filterTherapistResources } from "../utils";
import { Divider, Button } from "components";
import { BRAND_NAME } from "utils/constants";
import AddResourcesForPatient from "pages/AppPages/Library/components/AddResourcesForPatient";
import File from "pages/AppPages/TherapistPages/Resources/components/Files/components/File";
import AddFileForm from "pages/AppPages/TherapistPages/Resources/components/Files/components/AddFileForm";

interface AddFilesBodyProps {
  therapistFiles: any;
  publicFiles: any;
  selectedResources: any;
  setSelectedResources: any;
  handleEditFile: (file: FileResource) => void;
  handleOpenRemoveFileModal: (file: FileResource) => void;
  handleCloseSidePanel: () => void;
  currentFile: FileResource | null;
  setShowFileForm: any;
}

const AddFilesBody = ({
  therapistFiles,
  publicFiles,
  selectedResources,
  setSelectedResources,
  handleEditFile,
  handleOpenRemoveFileModal,
  handleCloseSidePanel,
  currentFile,
  setShowFileForm,
}: AddFilesBodyProps) => {
  const { uuid } = useParams();
  const { patient } = useGetPatients({ slug: uuid });
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const { user } = useCurrentUser();
  const [therapistFilesFiltered, setTherapistFilesFiltered] = useState<
    File[] | []
  >(filterTherapistResources(therapistFiles, patient?.uuid, []));
  const [showForm, setShowForm] = useState(false);
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTherapistFilesFiltered(
      filterTherapistResources(therapistFiles, patient?.uuid, [])
    );
  }, [therapistFiles, patient]);

  const handleSelectResource = (resource: FileResource, e: any) => {
    e.preventDefault();
    const isSelected = selectedResources.find(
      (item: FileResource) => item.uuid === resource.uuid
    );
    if (isSelected) {
      setSelectedResources(
        [...selectedResources].filter((item) => item.uuid !== resource.uuid)
      );
    } else {
      setSelectedResources([...selectedResources, resource]);
    }
  };

  return (
    <>
      <div className="bottom-modal_body flex space-between">
        <div>
          <div className="flex bottom-modal_library">
            {therapistFilesFiltered.length !== 0 &&
              therapistFilesFiltered.map((resource: any) => {
                const selected = selectedResources.find(
                  (item: FileResource) => item.uuid === resource.uuid
                );
                return (
                  <div
                    key={resource.uuid}
                    className={`bottom-modal_resource ${
                      selected ? "bottom-modal_selected" : ""
                    }`}
                    role="button"
                    tabIndex={0}
                    onClickCapture={(e) => handleSelectResource(resource, e)}
                  >
                    <File
                      file={resource}
                      key={resource.uuid}
                      isOwner={resource.addedBy === user?.id}
                      selected={selected}
                      handleFileEditClick={() => handleEditFile(resource)}
                      handleFileDeleteClick={() =>
                        handleOpenRemoveFileModal(resource)
                      }
                    />
                  </div>
                );
              })}
          </div>
          {publicFiles.length !== 0 && (
            <>
              <div className="bottom-modal_divider">
                <Divider />
              </div>
              <h3>From {BRAND_NAME}</h3>
              <div className="flex bottom-modal_library">
                {publicFiles.map((resource: FileResource) => {
                  const selected = selectedResources.find(
                    (item: any) => item.uuid === resource.uuid
                  );
                  return (
                    <div
                      key={resource.uuid}
                      className={`bottom-modal_resource ${
                        selectedResources.find(
                          (item: any) => item.uuid === resource.uuid
                        )
                          ? "bottom-modal_selected"
                          : ""
                      }`}
                      role="button"
                      tabIndex={0}
                      onClickCapture={(e) => handleSelectResource(resource, e)}
                    >
                      <File
                        file={resource}
                        key={resource.uuid}
                        isOwner={resource.addedBy === user?.id}
                        selected={selected}
                        handleFileEditClick={() => handleEditFile(resource)}
                        handleFileDeleteClick={() =>
                          handleOpenRemoveFileModal(resource)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div
          className={`bottom-modal_form ${
            showForm && "bottom-modal_form__visible"
          }`}
          ref={topRef}
        >
          {selectedResources.length === 0 ? (
            <AddFileForm
              onSuccess={handleCloseSidePanel}
              currentFile={currentFile}
              defaultSharedWith={patient}
              groupId={group?.uuid}
              showPatients={false}
              title="Add New File"
              submitButtonText="Add File"
            />
          ) : (
            <AddResourcesForPatient
              selectedResources={selectedResources}
              patient={patient}
              group={group}
              resourceType={"file"}
              setSelectedResources={setSelectedResources}
              setShowFileForm={setShowFileForm}
            />
          )}
          <Button
            name="bottom-modal_hide-form"
            value={""}
            icon="CloseCross"
            extraClass="bottom-modal_hide-form  bottom-modal_button-close transparent"
            onClick={() => setShowForm(false)}
          />
        </div>
      </div>

      {!showForm && (
        <Button
          name="show-form-button"
          value={
            selectedResources.length === 0 ? "Add New File" : "Selected Files"
          }
          extraClass="bottom-modal_showForm-button"
          onClick={() => {
            setShowForm(true);
          }}
        />
      )}
    </>
  );
};

export default AddFilesBody;
