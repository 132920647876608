import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "pages/AppPages/Library/LibraryPage.styles.scss";
import Resource from "pages/AppPages/Library/components/Resource";
import AddLibraryResourceButton from "pages/AppPages/Library/components/AddLibraryResourceButton";
import LibraryEmptyState from "pages/AppPages/Library/components/LibraryEmptyState";
import {
  useGetGroupLibrary,
  useGetGroupFiles,
  useCurrentUser,
  useFetchLibrary,
  useGetGroups,
  useFetchFiles,
  useGetTherapistLibrary,
  useGetCollections,
  useFetchCollections,
  useGetPublicLibrary,
  useGetPublicFiles,
} from "utils/hooks";
import {
  PageActionBar,
  Modal,
  BottomModal,
  Divider,
  PageLoading,
} from "components";
import RemoveResourceConfirmationModal from "./RemoveResourceConfirmationModal";
import File from "pages/AppPages/TherapistPages/Resources/components/Files/components/File";
import RemoveFileConfirmationModal from "./RemoveFileConfirmationModal";
import AddFileButton from "pages/AppPages/TherapistPages/Resources/components/Files/components/AddFileButton";
import { LibraryResource, FileResource, Collection } from "types";
import { isGroupActive } from "utils/helpers";
import AddLinksBody from "pages/AppPages/TherapistPages/SinglePatient/components/PatientLibrary/AddLinksBody";
import AddFilesBody from "pages/AppPages/TherapistPages/SinglePatient/components/PatientLibrary/AddFilesBody";
import { filterTherapistResources, createTitleForModal } from "./utils";
import {
  filterCollections,
  getUniqueResources,
  getCollectionImage,
  getUniqueFiles,
} from "../../../SinglePatient/components/PatientLibrary/utils";
import { BRAND_NAME } from "utils/constants";
import CollectionCard from "components/elements/CollectionCard";

const GroupLibrary = () => {
  const { t } = useTranslation(["common"]);
  const { uuid } = useParams();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const { groupResources, groupResByTherapist, groupResPublic } =
    useGetGroupLibrary({
      groupId: group.uuid,
    });
  const { groupFiles, groupPublicFiles } = useGetGroupFiles({
    groupId: group.uuid,
  });
  const { therapistResources, therapistFiles } = useGetTherapistLibrary({
    withFiles: true,
  });
  const { collections, groupCollections, groupPublicCollections } =
    useGetCollections({ groupId: group.uuid });
  const { publicResources } = useGetPublicLibrary();
  const { publicFiles } = useGetPublicFiles();
  const { fetchCollectionsByUser } = useFetchCollections();
  const [showResourceForm, setShowResourceForm] = useState(false);
  const [currentResource, setCurrentResource] =
    useState<LibraryResource | null>(null);
  const [currentCollection, setCurrentCollection] = useState<Collection | null>(
    null
  );
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [showFileForm, setShowFileForm] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileResource | null>(null);
  const [isRemoveFileModalOpen, setIsRemovalFileModalOpen] = useState(false);
  const { fetchGroupLibrary } = useFetchLibrary();
  const { fetchGroupFiles } = useFetchFiles();
  const { isTherapist, user } = useCurrentUser();
  const [therapistLinks, setTherapistLinks] = useState<LibraryResource[] | []>(
    filterTherapistResources(therapistResources, groupResources, collections)
  );
  const [filteredCollections, setFilteredCollections] = useState<
    Array<Collection> | []
  >(filterCollections(collections, group.uuid));
  const [restPublicResources, setRestPublicResources] = useState<
    Array<LibraryResource> | []
  >(getUniqueResources(publicResources, groupResources));

  const [selectedResources, setSelectedResources] = useState<any>([]);
  const [selectedCollections, setSelectedCollections] = useState<
    Array<Collection> | []
  >([]);

  const collResourcesIdsShared = useMemo(() => {
    if (!groupCollections || !groupPublicCollections) return;
    const collectionsSharedToGroup = [
      ...groupCollections,
      ...groupPublicCollections,
    ].filter((collection: Collection) =>
      collection.sharedWith.includes(group.uuid)
    );
    return collectionsSharedToGroup
      .map((collection: Collection) =>
        collection.collectionResources.map((r) => r.uuid)
      )
      .flat();
  }, [groupCollections, groupPublicCollections, group]);

  const combinedResources = useMemo(() => {
    if (!groupResByTherapist || !groupFiles) return;
    const links = groupResByTherapist.filter(
      (item: LibraryResource) => !collResourcesIdsShared?.includes(item.uuid)
    );
    return [
      ...links.map((resource: LibraryResource) => ({
        ...resource,
        type: "resource",
      })),
      ...groupFiles.map((file: FileResource) => ({ ...file, type: "file" })),
    ].sort(
      (a, b) =>
        new Date(b.updatedAt as string).getTime() -
        new Date(a.updatedAt as string).getTime()
    );
  }, [groupResByTherapist, collResourcesIdsShared, groupFiles]);

  const combinedPublicResources = useMemo(() => {
    if (!groupResPublic || !groupPublicFiles) return;
    const links = groupResPublic.filter(
      (item: LibraryResource) => !collResourcesIdsShared?.includes(item.uuid)
    );

    return [
      ...links.map((resource: LibraryResource) => ({
        ...resource,
        type: "resource",
      })),
      ...groupPublicFiles.map((file: FileResource) => ({
        ...file,
        type: "file",
      })),
    ].sort(
      (a, b) =>
        new Date(b.updatedAt as string).getTime() -
        new Date(a.updatedAt as string).getTime()
    );
  }, [groupResPublic, collResourcesIdsShared, groupPublicFiles]);

  const handleEditResource = (resource: LibraryResource) => {
    setCurrentResource(resource);
    setShowResourceForm(!showResourceForm);
  };

  const handleOpenRemovalModal = (resource: LibraryResource) => {
    setCurrentResource(resource);
    setIsRemovalModalOpen(true);
  };

  const handleOpenRemovalCollectionModal = ({ collection }: any) => {
    setCurrentCollection(collection);
    setIsRemovalModalOpen(true);
  };

  const handleCloseRemovalModal = () => {
    setCurrentResource(null);
    setCurrentCollection(null);
    setIsRemovalModalOpen(false);
  };

  const handleEditFile = (file: FileResource) => {
    setCurrentFile(file);
    setShowFileForm(!showFileForm);
  };

  const handleOpenRemoveFileModal = (file: FileResource) => {
    setCurrentFile(file);
    setIsRemovalFileModalOpen(true);
  };

  const handleCloseRemoveFileModal = () => {
    setCurrentFile(null);
    setIsRemovalFileModalOpen(false);
  };

  const handleCloseSidePanel = () => {
    setShowResourceForm(false);
    setCurrentResource(null);
    setCurrentFile(null);
    setShowFileForm(false);
    setSelectedResources([]);
    setSelectedCollections([]);
  };

  useEffect(() => {
    fetchGroupLibrary(group.uuid);
    fetchGroupFiles(group.uuid);
    fetchCollectionsByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  useEffect(() => {
    setTherapistLinks(
      filterTherapistResources(therapistResources, groupResources, collections)
    );
    setFilteredCollections(filterCollections(collections, group.uuid));
  }, [therapistResources, groupResources, collections, group.uuid]);

  useEffect(
    () =>
      setRestPublicResources(
        getUniqueResources(publicResources, groupResources)
      ),
    [publicResources, groupResources]
  );

  const isGroupActiveOrNoGroup = group ? isGroupActive(group) : true;

  return (
    <>
      {combinedResources &&
      combinedPublicResources &&
      groupCollections &&
      groupPublicCollections ? (
        <div className="library-page">
          {isGroupActive(group) && (
            <PageActionBar>
              <div className="group-library-page-header">
                {isTherapist && (
                  <div className="flex">
                    <AddLibraryResourceButton
                      title={t("resources.add_link_button")}
                      onClick={() => {
                        setShowResourceForm(!showResourceForm);
                      }}
                    />
                    <div className="ml-05">
                      <AddFileButton
                        title={t("resources.add_file_button")}
                        onClick={() => {
                          setShowFileForm(!showFileForm);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </PageActionBar>
          )}

          <div className="library-page-body library-page-condensed">
            <div className="library-page-section">
              {groupCollections?.length !== 0 &&
                groupCollections?.map((collection: Collection) => (
                  <CollectionCard
                    key={collection.uuid}
                    collection={collection}
                    title={collection.title}
                    items={collection.collectionResources.length}
                    imageSrc={String(getCollectionImage(collection))}
                    description={collection.description}
                    collectionId={collection.uuid}
                    showEdit={false}
                    showDelete={true}
                    onOpenDeleteModal={handleOpenRemovalCollectionModal}
                  />
                ))}
              {combinedResources?.length !== 0 &&
                combinedResources?.map((item) => {
                  if (item.type === "resource") {
                    return (
                      <Resource
                        resource={item as LibraryResource}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        onEditResource={handleEditResource}
                        onOpenDeleteModal={handleOpenRemovalModal}
                      />
                    );
                  }
                  if (item.type === "file") {
                    return (
                      <File
                        file={item}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        isGroupActiveOrNoGroup={isGroupActiveOrNoGroup}
                        showDeleteIcon={true}
                        handleFileEditClick={() =>
                          handleEditFile(item as FileResource)
                        }
                        handleFileDeleteClick={() =>
                          handleOpenRemoveFileModal(item as FileResource)
                        }
                      />
                    );
                  }
                  return null;
                })}
            </div>
            {(groupPublicCollections?.length !== 0 ||
              combinedPublicResources?.length !== 0) && (
              <div className="divider_wrapper">
                <h3 className="brand_name">From {BRAND_NAME}</h3>
                <div className="divider_line">
                  <Divider />
                </div>
              </div>
            )}
            <div className="library-page-section">
              {groupPublicCollections?.length !== 0 &&
                groupPublicCollections?.map((collection: Collection) => (
                  <CollectionCard
                    key={collection.uuid}
                    collection={collection}
                    title={collection.title}
                    items={collection.collectionResources.length}
                    imageSrc={String(getCollectionImage(collection))}
                    description={collection.description}
                    collectionId={collection.uuid}
                    showEdit={false}
                    showDelete={true}
                    onOpenDeleteModal={handleOpenRemovalCollectionModal}
                  />
                ))}
              {combinedPublicResources?.length !== 0 &&
                combinedPublicResources?.map((item) => {
                  if (item.type === "resource") {
                    return (
                      <Resource
                        resource={item as LibraryResource}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        onEditResource={handleEditResource}
                        onOpenDeleteModal={handleOpenRemovalModal}
                      />
                    );
                  }
                  if (item.type === "file") {
                    return (
                      <File
                        file={item}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        isGroupActiveOrNoGroup={isGroupActiveOrNoGroup}
                        showDeleteIcon={true}
                        handleFileEditClick={() => handleEditFile(item as any)}
                        handleFileDeleteClick={() =>
                          handleOpenRemoveFileModal(item as any)
                        }
                      />
                    );
                  }
                  return null;
                })}
            </div>
            {combinedResources?.length === 0 &&
              combinedPublicResources?.length === 0 &&
              groupCollections?.length === 0 &&
              groupPublicCollections?.length === 0 && <LibraryEmptyState />}
          </div>
        </div>
      ) : (
        <PageLoading vh50 />
      )}
      {isTherapist && (
        <BottomModal
          title={createTitleForModal(group, "Link")}
          isVisible={showResourceForm}
          handleClose={handleCloseSidePanel}
        >
          <AddLinksBody
            therapistLinks={therapistLinks}
            therapistCollections={filteredCollections}
            publicLinks={restPublicResources}
            selectedResources={selectedResources}
            setSelectedResources={setSelectedResources}
            selectedCollections={selectedCollections}
            setSelectedCollections={setSelectedCollections}
            handleCloseSidePanel={handleCloseSidePanel}
            currentResource={currentResource}
            setShowResourceForm={setShowResourceForm}
          />
        </BottomModal>
      )}

      {isTherapist && (
        <BottomModal
          isVisible={showFileForm}
          handleClose={handleCloseSidePanel}
          title={createTitleForModal(group, "File")}
        >
          <AddFilesBody
            therapistFiles={getUniqueFiles(therapistFiles, groupFiles)}
            publicFiles={getUniqueFiles(publicFiles, groupPublicFiles)}
            selectedResources={selectedResources}
            setSelectedResources={setSelectedResources}
            handleEditFile={handleEditFile}
            handleOpenRemoveFileModal={handleOpenRemoveFileModal}
            handleCloseSidePanel={handleCloseSidePanel}
            currentFile={currentFile}
            setShowFileForm={setShowFileForm}
          />
        </BottomModal>
      )}

      {isTherapist && (
        <Modal isOpen={isRemovalModalOpen} onClose={handleCloseRemovalModal}>
          <RemoveResourceConfirmationModal
            resource={currentResource}
            collection={currentCollection}
            onCancel={handleCloseRemovalModal}
            group={group}
          />
        </Modal>
      )}

      {isTherapist && (
        <Modal
          isOpen={isRemoveFileModalOpen}
          onClose={handleCloseRemoveFileModal}
        >
          <RemoveFileConfirmationModal
            file={currentFile}
            onCancel={handleCloseRemoveFileModal}
            group={group}
          />
        </Modal>
      )}
    </>
  );
};

export default GroupLibrary;
