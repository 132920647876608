import { useDispatch } from "react-redux";
import { updateRemovedPracticeIdAction } from "store/actions/users";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";

const useUpdateRemovedPracticeId = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const updateRemovedPracticeId = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              updateRemovedPracticeId {
                removedPracticeId
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resData = await res.json();

      if (res.status !== 200 && res.status !== 201) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn(`For security purposes please log in again.`);
          logOut();
        } else {
          toast.error(`Hmm, something went wrong.`);
          throw new Error("Failed!");
        }
      }

      dispatch(
        updateRemovedPracticeIdAction(resData.data.updateRemovedPracticeId)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    updateRemovedPracticeId,
  };
};

export default useUpdateRemovedPracticeId;
