import { PURGE } from "redux-persist";
import { Template } from "types";

const initialState = {
  privateTemplates: null as Array<Template> | null,
  publicTemplates: null as Array<Template> | null,
  privateTemplateTags: [],
  publicTemplateTags: [],
};

interface TemplateReducerType {
  type: any;
  payload: any;
}

export default (
  state = initialState,
  { type, payload }: TemplateReducerType
) => {
  switch (type) {
    case "FETCH_PUBLIC_TEMPLATES":
      return {
        ...state,
        publicTemplates: [...payload],
      };
    case "FETCH_PRIVATE_TEMPLATES":
      return {
        ...state,
        privateTemplates: [...payload],
      };
    case "ADD_PUBLIC_TEMPLATE":
      if (!state.publicTemplates) return state;
      return {
        ...state,
        publicTemplates: [...state.publicTemplates, payload],
      };
    case "ADD_PRIVATE_TEMPLATE":
      if (!state.privateTemplates) return state;
      return {
        ...state,
        privateTemplates: [...state.privateTemplates, payload],
      };
    case "UPDATE_TEMPLATE":
      if (!state.privateTemplates || !state.publicTemplates) return state;
      return {
        ...state,
        privateTemplates: state.privateTemplates.map((t: any) =>
          t.uuid === payload.uuid
            ? {
                ...t,
                ...payload,
              }
            : t
        ),
        publicTemplates: state.publicTemplates.map((t: any) =>
          t.uuid === payload.uuid
            ? {
                ...t,
                ...payload,
              }
            : t
        ),
      };
    case "DELETE_TEMPLATE":
      if (!state.privateTemplates || !state.publicTemplates) return state;
      return {
        ...state,
        privateTemplates: state.privateTemplates.filter(
          (t: any) => t.uuid !== payload.uuid
        ),
        publicTemplates: state.publicTemplates.filter(
          (t: any) => t.uuid !== payload.uuid
        ),
      };
    case "FETCH_ALL_PUBLIC_TEMPLATE_TAGS":
      return {
        ...state,
        publicTemplateTags: [...payload],
      };
    case "FETCH_ALL_PRIVATE_TEMPLATE_TAGS":
      return {
        ...state,
        privateTemplateTags: [...payload],
      };
    case "CREATE_PUBLIC_TEMPLATE_TAG":
      return {
        ...state,
        publicTemplateTags: [...state.publicTemplateTags, payload],
      };
    case "CREATE_PRIVATE_TEMPLATE_TAG":
      return {
        ...state,
        privateTemplateTags: [...state.privateTemplateTags, payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
