import React, { useEffect } from "react";
import "./AddResourcesForPatient.styles.scss";
import { Icon, Divider, Button, Animate } from "components";
import { getUrlIcon } from "../../utils";
import { LibraryResource, Patient, Collection, Group } from "types";
import { useAssignLibraryResources, useAssignFiles } from "utils/hooks";
import { getFileTypeIconFromUrl } from "pages/AppPages/TherapistPages/Resources/components/Files/utils";

interface AddResourcesForPatientProps {
  selectedResources: any;
  selectedCollections?: Collection[] | [];
  patient?: Patient;
  group?: Group;
  resourceType: string;
  setSelectedResources: (arr: any) => void;
  setSelectedCollections?: (arr: any) => void;
  setShowFileForm?: (arg: boolean) => void;
  setShowResourceForm?: (arg: boolean) => void;
}

const AddResourcesForPatient = ({
  selectedResources,
  selectedCollections,
  patient,
  group,
  resourceType,
  setSelectedResources,
  setSelectedCollections,
  setShowFileForm,
  setShowResourceForm,
}: AddResourcesForPatientProps) => {
  const { assignLibraryResources, loading, assigned } =
    useAssignLibraryResources({});
  const {
    assignFiles,
    loading: loadingFiles,
    assigned: assignedFiles,
  } = useAssignFiles({});

  useEffect(() => {
    if (assigned || assignedFiles) {
      setSelectedResources([]);
      setSelectedCollections && setSelectedCollections([]);
      setShowResourceForm && setShowResourceForm(false);
      setShowFileForm && setShowFileForm(false);
    }
  }, [
    assigned,
    assignedFiles,
    setSelectedResources,
    setShowResourceForm,
    setSelectedCollections,
    setShowFileForm,
  ]);

  const handleRemoveLink = (link: LibraryResource) => {
    const isSelected = selectedResources.find(
      (item: any) => item.uuid === link.uuid
    );
    if (isSelected) {
      setSelectedResources(
        [...selectedResources].filter((item) => item.uuid !== link.uuid)
      );
    } else {
      setSelectedResources([...selectedResources, link]);
    }
  };

  const handleRemoveCollection = (collection: Collection) => {
    if (!selectedCollections || !setSelectedCollections) return;
    const isSelected = selectedCollections.find(
      (item) => item.uuid === collection.uuid
    );
    if (isSelected) {
      setSelectedCollections(
        [...selectedCollections].filter((item) => item.uuid !== collection.uuid)
      );
    } else {
      setSelectedCollections([...selectedCollections, collection]);
    }
  };

  const handleSubmit = async () => {
    const resourceIds = selectedResources?.map((r: any) => r.uuid);
    if (resourceType === "link") {
      const collectionIds = selectedCollections?.map((c: Collection) => c.uuid);
      await assignLibraryResources({
        resourceIds,
        collectionIds: collectionIds ? collectionIds : [],
        patientId: patient?.uuid,
        groupId: group?.uuid,
      });
    } else if (resourceType === "file") {
      await assignFiles({
        fileIds: resourceIds,
        patientId: patient?.uuid,
        groupId: group?.uuid,
      });
    }
  };

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="add-links_container">
      <h1 className="add-links_title">{`Add ${
        resourceType === "link" ? "Links" : "Files"
      }`}</h1>
      {selectedResources.length !== 0 &&
        selectedResources.map((link: any) => (
          <div key={link.uuid}>
            <Animate animation="fadeInLeft" delay=".25">
              <div className="add-links_link">
                <div className="flex">
                  <div className="add-links_icon">
                    <Icon
                      src={
                        resourceType === "link"
                          ? getUrlIcon(link.resourceUrl)
                          : getFileTypeIconFromUrl(link.fileUrl)
                      }
                    />
                  </div>
                  {resourceType === "link" ? (
                    <a href={link.resourceUrl} target="_blank" rel="noreferrer">
                      <span className="add-links_linkTitle">{link.title}</span>
                    </a>
                  ) : (
                    <Button
                      name="add-links_item-button"
                      value={link.title}
                      extraClass="add-links_linkTitle transparent btnText"
                      onClick={() => openInNewTab(link.fileUrl)}
                    />
                  )}
                  <Button
                    name="add-links_remove-button"
                    value={""}
                    icon="CloseCrossBold"
                    extraClass="add-links_remove-link transparent"
                    onClick={() => handleRemoveLink(link)}
                  />
                </div>
                <Divider />
              </div>
            </Animate>
          </div>
        ))}
      {selectedCollections?.length !== 0 &&
        selectedCollections?.map((collection: Collection) => (
          <div key={collection.uuid}>
            <Animate animation="fadeInLeft" delay=".25">
              <div className="add-links_link">
                <div className="flex">
                  <div className="add-links_icon">
                    <Icon src={"CollectionIcon"} />
                  </div>
                  <span className="add-links_linkTitle">
                    {collection.title}
                  </span>
                  <Button
                    name="add-links_remove-button"
                    value={""}
                    icon="CloseCrossBold"
                    extraClass="add-links_remove-link transparent"
                    onClick={() => handleRemoveCollection(collection)}
                  />
                </div>
                <Divider />
              </div>
            </Animate>
          </div>
        ))}
      <Button
        name="add-links_submit"
        value={`Add ${resourceType === "link" ? "Links" : "Files"} for ${
          patient ? patient.firstName : group ? group?.name : "patients"
        }`}
        extraClass="add-links_submit pill"
        isLoading={loading || loadingFiles}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default AddResourcesForPatient;
