import "./AssignToggle.styles.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { Toggle } from "components";

type AssignToggleProps = {
  checked: boolean;
  label: string;
  onChange: (e: any) => void;
  extraClass?: string;
  ref?: any;
};

const AssignToggle = ({
  checked,
  label,
  onChange,
  extraClass,
  ref,
}: AssignToggleProps) => {
  const { t } = useTranslation("common");

  return (
    <div className={`toggle-default ${extraClass && extraClass}`}>
      <div className="toggle-default_label">{t(label)}</div>
      <Toggle
        name={"default"}
        checked={checked}
        onChange={onChange}
        forwardRef={ref}
      />
    </div>
  );
};

export default AssignToggle;
