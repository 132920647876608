import { useLogout } from "utils/hooks";
import { toast } from "react-toastify";

interface resendPracticeInviteProps {
  practiceId: string;
  email: string;
}

const useResendPracticeInvite = () => {
  const { logOut } = useLogout();
  const resendPracticeInvite = async ({
    practiceId,
    email,
  }: resendPracticeInviteProps) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              resendTherapistPracticeInvite(
                practiceId: "${practiceId}",
                email: "${email}",
              )
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        toast("Invitation has been resent");
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    resendPracticeInvite,
  };
};

export default useResendPracticeInvite;
