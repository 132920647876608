import "./DeleteNoteConfirmationModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useDeleteNote } from "utils/hooks";
import { Note } from "types";

type Props = {
  note: Note | null;
  onCancel: () => void;
  onDelete: () => void;
};

const DeleteNoteConfirmationModal = ({ note, onCancel, onDelete }: Props) => {
  const { deleteGroupNote } = useDeleteNote();

  return (
    <div className="note-deletion-confirmation">
      <div className="note-deletion-confirmation__icon">
        <Icon src={WarningIcon} alt="Warning Icon" />
      </div>
      <div className="note-deletion-confirmation__title">
        Are you sure you want to delete this note?
      </div>
      <div className="note-deletion-confirmation__body">
        You won&apos;t be able to undo this action and you will no longer have
        access.
      </div>
      <div className="note-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_delete_note_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            note && deleteGroupNote({ note });
            onCancel();
            onDelete();
          }}
          name={`confirm_delete_note_button`}
          value="Yes, delete it"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default DeleteNoteConfirmationModal;
