import Icon from "components/utility/Icon";
import "./Avatar.styles.scss";
import React from "react";
import { capitalizeFirstLetter, getInitials } from "utils/helpers";
import { useCurrentUser } from "utils/hooks";
import { Patient, User } from "types";

interface AvatarProps {
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string;
  showInitials?: boolean;
  color?: string;
  icon?: string;
  user?: User | Patient | null;
  isGroup?: boolean;
}

const Avatar = ({
  firstName,
  lastName,
  fullName,
  showInitials = false,
  color,
  icon,
  user,
  isGroup,
}: AvatarProps) => {
  const { user: currentUser, isTherapist } = useCurrentUser();
  let initials;

  if (fullName) {
    initials = getInitials(fullName);
  }

  if (firstName && lastName) {
    initials = `${firstName?.substring(0, 1)} ${lastName?.substring(0, 1)}`;
  }

  if (!firstName && !lastName && !fullName && !user) {
    initials = `${currentUser?.firstName?.substring(
      0,
      1
    )} ${currentUser?.lastName?.substring(0, 1)}`;
  }

  const userIcon = icon || user?.icon || currentUser?.icon;

  return (
    <div
      className="avatar-wrapper"
      style={{ backgroundColor: color || user?.color || currentUser?.color }}
    >
      {isGroup ? (
        <Icon src="Group" />
      ) : showInitials || (isTherapist && !user) ? (
        initials
      ) : (
        <Icon src={capitalizeFirstLetter(userIcon)} />
      )}
    </div>
  );
};

export default Avatar;
