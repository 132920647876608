import { RootStateOrAny, useSelector } from "react-redux";
import { SAFETY_PLAN_STATUSES } from "../../constants";
import { Assignment, AssignmentSubmission } from "types";

interface UseSafetyPlanProps {
  patientId?: string;
}

const useSafetyPlan = ({ patientId }: UseSafetyPlanProps) => {
  const submissions = useSelector(
    (state: RootStateOrAny) => state.submissions?.submissions || []
  );
  const allAssignments = useSelector(
    (state: RootStateOrAny) => (state.assignments && state.assignments) || []
  );

  const safetyPlanFromAssignments = allAssignments?.assignments?.filter(
    (assignment: Assignment) =>
      assignment.type === "SAFETY_PLAN" &&
      assignment.status !== SAFETY_PLAN_STATUSES.DELETED
  );

  const firstSafetyPlan =
    safetyPlanFromAssignments && safetyPlanFromAssignments[0];

  let actualFirstSafetyPlanSubmission;
  if (firstSafetyPlan) {
    const safetyPlanSubmissions = submissions.filter(
      (submission: AssignmentSubmission) =>
        submission.assignmentId === firstSafetyPlan.uuid
    );
    actualFirstSafetyPlanSubmission = safetyPlanSubmissions[0];
    for (let i = 0; i < safetyPlanSubmissions.length; i++) {
      if (
        Number(actualFirstSafetyPlanSubmission.createdAt) <
        Number(safetyPlanSubmissions[i].createdAt)
      ) {
        actualFirstSafetyPlanSubmission = safetyPlanSubmissions[i];
      }
    }
  }

  const patientSafetyPlan = safetyPlanFromAssignments?.filter(
    (safetyPlan: any) => safetyPlan.patientId === patientId
  );

  const safetyPlanSubmission =
    patientSafetyPlan &&
    submissions?.filter(
      (submission: any) =>
        submission.assignmentId === patientSafetyPlan[0]?.uuid
    );

  return {
    isSafetyPlanAvailable: safetyPlanFromAssignments?.length > 0,
    firstSafetyPlan,
    safetyPlans: safetyPlanFromAssignments,
    actualFirstSafetyPlanSubmission,
    safetyPlanFromAssignments,
    safetyPlanSubmission:
      safetyPlanSubmission &&
      safetyPlanSubmission[safetyPlanSubmission?.length - 1],
  };
};

export default useSafetyPlan;
