import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useFetchFiles from "./useFetchFiles";

const useGetPublicFiles = (patientId) => {
  const dataRef = useRef(false);
  const { fetchPublicFiles } = useFetchFiles();
  const [publicPatientFiles, setPublicPatientFiles] = useState([]);

  const files = useSelector((state) => state.files && state.files);

  useEffect(() => {
    if (dataRef.current) return;
    fetchPublicFiles();
    dataRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patientId) {
      setPublicPatientFiles(
        files?.files?.filter((file) =>
          file.sharedWith.find((s) => s.value === patientId)
        )
      );
    }
  }, [files.files, patientId]);

  return {
    publicFiles: files.files,
    publicPatientFiles,
  };
};

export default useGetPublicFiles;
