import React from "react";
import { EnhancedFieldType, Field, GeneralFieldType } from "types";
import {
  DateField,
  MoodScaleField,
  MultiResponseField,
  NegativeEmotionsField,
  RangeField,
  SelectField,
  StringField,
  TextAreaField,
  ThoughtDistortionsField,
  TimeField,
} from "../fieldComponents";
import "../fieldComponents/FieldStyles.styles.scss";
import { useAssignmentStore } from "../../assignmentStore";
import InputListField from "components/utility/GeneratedField/components/InputListField";
import MultiValueField from "components/utility/GeneratedField/components/MultiValueField";
import CustomMoodScaleField from "../fieldComponents/CustomMoodScaleField";
import { CheckboxGrid } from "components";

interface Props {
  field: Field;
  defaultValue?: any;
  onChange: (value: any) => void;
  error?: string;
}
const EditableFields = ({ field, onChange, defaultValue }: Props) => {
  const fieldType = field.type as GeneralFieldType | EnhancedFieldType;
  const commonProps = { field, defaultValue, onChange };
  const { state } = useAssignmentStore();

  switch (fieldType) {
    case "STRING":
      return <StringField {...commonProps} />;
    case "TEXTAREA":
      return <TextAreaField {...commonProps} />;
    case "SELECT_FIELD":
      if (field.fromPrevious) {
        const previousField = state.activeAssignment?.sections
          .flatMap((section) => section.fields)
          .find((f) => {
            return f.name === field.fromPreviousField;
          })?.uuid;
        if (previousField) {
          const val = state.responses[previousField]?.value;
          if (Array.isArray(val)) {
            field.options = val.map((v) => {
              return { label: v, value: v };
            });
          }
          if (typeof val === "string") {
            try {
              const parsedValue = JSON.parse(val);
              field.options = parsedValue.map((v: string) => {
                return { label: v, value: v };
              });
            } catch (e) {
              field.options = [];
            }
          }
          field.options = field.options.filter((v) => v.label !== "");
        }
      }
      return <SelectField {...commonProps} />;
    case "DATE":
      return <DateField {...commonProps} />;
    case "TIME":
      return <TimeField {...commonProps} />;
    case "SCALE":
      return <RangeField {...commonProps} />;
    case "CHECKBOX_GRID":
      return (
        <CheckboxGrid
          {...field}
          label={field.label}
          checkboxes={field.checkboxOptions}
          checkboxRows={field.checkboxRows}
          onCheckboxRowsChange={(v) => onChange(v)}
        />
      );
    case "MULTI_RESPONSE":
      return <MultiResponseField {...commonProps} />;
    case "SELECT":
      switch (field.choicesKey) {
        case "THOUGHT_DISTORTIONS":
          return <ThoughtDistortionsField {...commonProps} />;
        case "NEGATIVE_EMOTIONS":
          return <NegativeEmotionsField {...commonProps} />;
        default:
          return <></>;
      }
    case "THOUGHT_DISTORTIONS_FIELD":
      return <ThoughtDistortionsField {...commonProps} />;
    case "MOOD_SCALE":
      return <MoodScaleField {...commonProps} />;
    case "NEGATIVE_EMOTIONS_FIELD":
      return <NegativeEmotionsField {...commonProps} />;
    case "CUSTOM_MOOD_SCALE":
      return <CustomMoodScaleField {...commonProps} />;
    case "INPUT_LIST":
      return (
        <InputListField
          {...field}
          name={field.name}
          label={field.label}
          value={defaultValue}
          onChange={onChange}
          hint={field.hint}
          config={field.config}
          uuid={field.uuid}
        />
      );
    case "MULTI_VALUE":
      return (
        <MultiValueField
          {...field}
          name={field.name}
          label={field.label}
          value={defaultValue}
          onChange={onChange}
          config={field.config}
          uuid={field.uuid}
        />
      );
    case "MULTI_SELECT":
      return <StringField {...commonProps} />;

    default:
      return <></>;
  }
};

export default EditableFields;
