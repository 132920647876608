import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { FileResource } from "types";
import { useFetchFiles, useCurrentUser } from "utils/hooks";

interface UseGetGroupFilesProps {
  groupId?: string;
}

const useGetGroupLibrary = ({ groupId }: UseGetGroupFilesProps) => {
  const { fetchGroupFiles } = useFetchFiles();
  const { user } = useCurrentUser();
  const [groupFiles, setGroupFiles] = useState<Array<FileResource>>([]);
  const [groupPublicFiles, setGroupPublicFiles] =
    useState<Array<FileResource> | null>(null);
  const templateMasterId = process.env.REACT_APP_TEMPLATE_MASTER_ID;

  const files = useSelector(
    (state: RootStateOrAny) => state.files && state.files
  );

  useEffect(() => {
    if (groupId) {
      fetchGroupFiles(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (files.groupFiles) {
      setGroupFiles(
        files.groupFiles.filter((f: FileResource) => f.addedBy === user?.id)
      );
      setGroupPublicFiles(
        files.groupFiles.filter(
          (f: FileResource) => f.addedBy === templateMasterId
        )
      );
    }
  }, [groupId, files.groupFiles, templateMasterId, user]);

  return {
    groupFiles,
    groupPublicFiles,
  };
};

export default useGetGroupLibrary;
