import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthenticationHeader from "../components/AuthenticationHeader";
import AuthenticationFooter from "../components/AuthenticationFooter";
import {
  Animate,
  Button,
  ButtonLoading,
  Checkbox,
  Input,
  Link,
  Radio,
} from "components";
import { BRAND_NAME } from "utils/constants";

import { useSignUp, useViewport, useQueryParams } from "utils/hooks";
import jwtDecode from "jwt-decode";
import { SignUpArgs } from "utils/hooks/auth/useSignUp";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const { t } = useTranslation(["common"]);
  const { hasUrlParams } = useQueryParams();
  const isPatientInvite = hasUrlParams("fromInvite");
  const emailParam = hasUrlParams("email");
  const qrTokenParam = hasUrlParams("qrToken");
  const inviteTherapistTokenParam = hasUrlParams("inviteTherapistToken");
  const fbclid = hasUrlParams("fbclid");
  const { register, handleSubmit, errors } = useForm();
  const { submitSignup, isSubmitting } = useSignUp({
    fromInvite: isPatientInvite,
    inviteTherapistToken: inviteTherapistTokenParam,
    fbclid: fbclid,
  });
  const { isMobile } = useViewport();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isTosChecked, setIsTosChecked] = useState(false);

  useEffect(() => {
    setIsDisabled(!isTosChecked);
    localStorage.clear();
  }, [isTosChecked]);

  let decodedToken;

  if (qrTokenParam) {
    decodedToken = jwtDecode(qrTokenParam);
  }

  if (inviteTherapistTokenParam) {
    decodedToken = jwtDecode<any>(inviteTherapistTokenParam);
  }

  const onSubmit = (args: SignUpArgs) => {
    if (qrTokenParam) {
      args.isTherapist = false;
    }
    submitSignup(args);
  };

  const hideIsTherapistRadio =
    isPatientInvite || qrTokenParam || inviteTherapistTokenParam;

  const subline = qrTokenParam
    ? t("auth.sign_up_subline_invite", {
        therapistName: decodedToken?.therapistFullName,
      })
    : t(
        isPatientInvite
          ? "auth.sign_up_subline_patient"
          : "auth.sign_up_subline_therapist"
      );

  return (
    <div id="signup_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader subline={subline} />
      </Animate>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between mobile-column">
          <Animate
            animation="fadeInLeft"
            delay=".25"
            width={isMobile ? "100%" : "48%"}
          >
            <Input
              name="first_name"
              type="text"
              label={t("auth.first_name")}
              placeholder={t("auth.first_name_placeholder")}
              forwardRef={register({ required: true })}
              error={errors.first_name && t("auth.first_name_validation")}
            />
          </Animate>
          <Animate
            animation="fadeInLeft"
            delay=".35"
            width={isMobile ? "100%" : "48%"}
          >
            <Input
              name="last_name"
              type="text"
              label={t("auth.last_name")}
              placeholder={t("auth.last_name_placeholder")}
              forwardRef={register({ required: true })}
              error={errors.last_name && t("auth.last_name_validation")}
            />
          </Animate>
        </div>
        <Animate animation="fadeInLeft" delay=".45">
          <Input
            name="email"
            type="email"
            label={t("auth.email")}
            defaultValue={emailParam || undefined}
            placeholder={t("auth.email_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.email && t("auth.email_validation")}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".55">
          <Input
            name="password"
            type="password"
            label={t("auth.password")}
            placeholder={t("auth.password_placeholder")}
            forwardRef={register({
              required: t("auth.password_validation"),
              minLength: {
                value: 8,
                message: t("auth.password_min_length_validation"),
              },
            })}
            error={errors.password && errors.password.message}
          />
        </Animate>
        <Animate
          animation="fadeInLeft"
          delay=".65"
          style={{
            display: `${hideIsTherapistRadio ? "none" : "flex"}`,
          }}
        >
          <Radio
            inputName="isTherapist"
            choices={[
              {
                label: t("auth.is_therapist"),
                value: true,
              },
              {
                label: t("auth.is_patient"),
                value: false,
              },
            ]}
            forwardRef={register({
              required: hideIsTherapistRadio
                ? false
                : t("auth.user_type_validation"),
            })}
            error={errors.isTherapist && errors.isTherapist.message}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".65">
          <div className="mb-4">
            <Checkbox
              name="tos"
              label={
                <>
                  {t("auth.tos_agreement")} {BRAND_NAME}&apos;s{" "}
                  <Link to="https://reflectiveapp.com/tos/" newTab>
                    {t("auth.tos_link")}
                  </Link>
                </>
              }
              ariaLabel={t("auth.tos_agreement")}
              onChange={(e) => setIsTosChecked(e.target.checked)}
              forwardRef={register({
                required: t("auth.tos_validation"),
              })}
              error={errors.tos && errors.tos.message}
            />
          </div>
        </Animate>
        <Animate animation="fadeInLeft" delay=".75">
          <Button
            type="submit"
            name="signup_button"
            value={isSubmitting ? <ButtonLoading /> : t("auth.sign_up_button")}
            fullWidth
            isLoading={isSubmitting}
            disabled={isDisabled}
          />
        </Animate>
      </form>
      <Animate animation="fadeInLeft" delay=".75">
        <AuthenticationFooter
          lineOne={
            <>
              {t("auth.already_have_account")}{" "}
              <Link
                to={qrTokenParam ? `/login?qrToken=${qrTokenParam}` : "/login"}
              >
                {t("auth.login_button")}
              </Link>
            </>
          }
        />
      </Animate>
    </div>
  );
};

export default SignUp;
