/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./SidePanel.styles.scss";
import React, { useState, useEffect, ReactElement } from "react";
import { Portal } from "react-portal";
import SlidingPane from "react-sliding-pane";
import { Icon } from "components";

type SidePanelProps = {
  isVisible: boolean;
  onClose: () => any;
  placement?: "left" | "right" | "bottom" | undefined;
  title?: string | ReactElement;
  children: any;
  extraClass?: string;
  width?: string;
};
type SidePanelHeaderProps = {
  title: string | ReactElement;
  onIconClick?: () => any;
};

const SidePanelHeader = ({ title, onIconClick }: SidePanelHeaderProps) => {
  return (
    <div className="side-panel__header flex">
      <div className="side-panel__header-icon" onClick={onIconClick}>
        <Icon src={"ArrowBack"} />
      </div>
      {typeof title === "string" ? (
        <h4 className="side-panel__header-title">{title}</h4>
      ) : (
        title
      )}
    </div>
  );
};

const SidePanel = ({
  isVisible,
  onClose,
  placement,
  title,
  children,
  extraClass,
  width = "400",
}: SidePanelProps) => {
  const [isPanelVisible, setIsPanelVisible] = useState(isVisible);

  useEffect(() => {
    setIsPanelVisible(isVisible);
  }, [isVisible]);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Portal>
      <SlidingPane
        from={placement}
        isOpen={isPanelVisible}
        onRequestClose={handleOnClose}
        className={`side-panel ${extraClass}`}
        overlayClassName={`side-panel-background ${extraClass}`}
        width={`${width}px`}
        shouldCloseOnEsc
        hideHeader
      >
        {title && <SidePanelHeader title={title} onIconClick={handleOnClose} />}
        {children}
      </SlidingPane>
    </Portal>
  );
};

export default SidePanel;
