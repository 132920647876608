import React from "react";
import { Button, UpgradeTooltip } from "components";
import PatientIcon from "assets/icons/users-medical.svg";
import { useCurrentUser, useGetPatients } from "utils/hooks";
import { useTranslation } from "react-i18next";

interface AddPatientButtonProps {
  onClick?: () => void;
}

const AddPatientButton = ({ onClick }: AddPatientButtonProps) => {
  const { t } = useTranslation(["common"]);
  const { user } = useCurrentUser();
  const { patients } = useGetPatients({});

  const patientLimit = user?.plan?.patientLimit || 0;
  // if the user doesn't have a plan they are likley a patient who cannot currently add patients anyway
  const limitReached = patients?.length >= patientLimit;

  return (
    <div data-tip data-for="patient-upgrade-tip">
      <Button
        name="add_patient_button"
        value={t("patients.add_patient_button")}
        pill
        icon={PatientIcon}
        size="small"
        onClick={onClick}
        disabled={limitReached}
      />
      {limitReached && (
        <UpgradeTooltip
          tooltipId="patient-upgrade-tip"
          limitedEntity="patients"
        />
      )}
    </div>
  );
};

export default AddPatientButton;
