import { Practice, User } from "types";

export const fetchPracticesAction = (practices: Practice[]) => {
  return {
    type: "FETCH_PRACTICES",
    payload: practices,
  };
};

export const fetchPracticeUsersAction = (practiceUsers: User[]) => {
  return {
    type: "FETCH_PRACTICE_USERS",
    payload: practiceUsers,
  };
};

export const createPracticeAction = (practice: Practice) => {
  return {
    type: "CREATE_PRACTICE",
    payload: practice,
  };
};

export const updatePracticeAction = (practice: Practice) => {
  return {
    type: "UPDATE_PRACTICE",
    payload: practice,
  };
};
