import { useState } from "react";
import ReactGA from "react-ga4";
import {
  updateLibraryResources,
  deleteGroupLibraryResourcesAction,
  deletePublicPatientLibraryResourceAction,
} from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";

type Props = {
  resourceId: string;
  patientId?: string;
  groupId?: string;
};

const useUnassignLibraryResource = ({
  resourceId,
  patientId,
  groupId,
}: Props) => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const unassignLibraryResource = () => {
    setSuccess(false);
    setLoading(true);

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                unassignLibraryResourceForPatientOrGroup(
                  resourceId: ${resourceId ? `"${resourceId}"` : `""`}, 
                  patientId: ${patientId ? `"${patientId}"` : `""`},
                  groupId: ${groupId ? `"${groupId}"` : `""`}
                ) {
                    resourceUrl
                    title
                    description
                    imageUrl
                    uuid
                    addedBy
                    updatedAt
                    sharedWith {
                      label
                      value
                    }
                  }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "unassign_library_resource",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              setLoading(false);
              return true;
            } else {
              toast("Resource removed");
              resolve();
              setLoading(false);
              ReactGA.event("unassign_library_resource_success", {
                user_id: user?.id,
                resource: resData.data.unassignLibraryResourceForPatientOrGroup,
              });
              dispatch(
                updateLibraryResources([
                  resData.data.unassignLibraryResourceForPatientOrGroup,
                ])
              );
              dispatch(
                deletePublicPatientLibraryResourceAction(
                  resData.data.unassignLibraryResourceForPatientOrGroup
                )
              );
              if (groupId) {
                dispatch(
                  deleteGroupLibraryResourcesAction([
                    resData.data.unassignLibraryResourceForPatientOrGroup.uuid,
                  ])
                );
              }
              return setSuccess(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    unassignLibraryResource,
    loading,
    success,
  };
};

export default useUnassignLibraryResource;
